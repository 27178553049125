<template>
  <div class="component-wrapper" style="width: 100%;">
    <div :class="{'no-scroll':open}" class="absolute-content">
      <div style="background-color:var(--v-secondary-base);">
        <v-img
          src="https://cf.bstatic.com/xdata/images/hotel/max1024x768/394041816.jpg?k=58d10c7559a0882b72a15ec6f2bab50cad358bcde83ce5f281963cd52f81c681&o=&hp=1"
          class="white--text align-end"
          gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
          height="200px"
          aspect-ratio="16 / 9"
        >
        </v-img>
        <div class="pa-2">
          <h1 class="white--text">Grand Hotel Kalamata</h1>
        </div>
      </div>

      <v-container>
        <v-row>
          <v-col
            v-for="(category, i) in categories"
            :key="i"
            cols="6"
          >
            <v-btn outlined class="secondary--text pa-1" style="width: 100%;height: auto;" @click="onCategorySelect(i)">
              <div class="d-flex flex-column align-center justify-center" style="width: 100%;">
                <v-img
                  :src="category.image"
                  class="white--text align-end"
                  gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                  height="150px"
                  width="100%"
                  aspect-ratio="16 / 9"
                >
                </v-img>

                <span class="body-1 text-center py-1">
                  {{ category.name }}
                </span>
              </div>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div
      v-for="(category, i) in categories" 
      :key="i"
      v-show="dialog[i]"
      style="position:absolute; top: 0; bottom: 0; overflow-y: auto;  width: 100%; z-index: 0;"
      class="white"
    >
      <v-btn class="rounded-pill return-button-sticky secondary white--text elevation-5" @click="closeCategory(i)" text> <v-icon>mdi-arrow-left-thin</v-icon> Grand Hotel Kalamata</v-btn>
      <div class="pa-2">
        <h2>
          <v-img
            :src="category.image"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            height="150px"
            width="100%"
            aspect-ratio="16 / 9"
          >
          </v-img>
          {{ category.name }}
        </h2>

        <div v-html="category.text"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default{
  created(){
    if(this.$store.state.base.mobileLang){
      this.categories=require(`../../../examples/hotel_${this.$store.state.base.mobileLang.code}.json`);
    }

    for(var i = 0; i < this.categories.length; i++){
      this.$set(this.dialog, i, false);
    }
  },
  data (){
    return {
      tab: null,
      dialog:[],
      categories:[]
    }
  },
  methods:{
    onCategorySelect(category){
      this.$set(this.dialog, category, true);
    },
    closeCategory(category){
      this.$set(this.dialog, category, false);
    }
  },
  computed:{
    open(){
      for(var i = 0; i < this.dialog.length; i++){
        if(this.dialog===true) return true;
      }
      return false;
    }
  }
}
</script>

<style scoped lang="scss">
.v-tab {
  text-transform: none !important;
} 

.no-scroll{
  overflow: hidden;
}
</style>