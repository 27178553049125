<template>
  <v-card color="secondary">
    <v-menu offset-y max-width="100%" :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <div>
          <v-text-field
            solo
            hide-details
            label="Search"
            append-icon="mdi-magnify"
            @keyup.enter="onSearch"
            v-bind="attrs"
            v-on="on"
            clearable
            color="secondary"
          >
            <template v-slot:prepend-inner>
              <v-icon class="pr-4" @click="$emit('close')"
                >mdi-arrow-left</v-icon
              >
            </template>
            <template v-slot:append>
              <v-icon class="pr-4" @click="onSearch">mdi-magnify</v-icon>
            </template>

            <template v-slot:label>
              <span class="font-italic body-2 pl-4">
                {{ $t("general.search") }}
              </span>
            </template>
          </v-text-field>
        </div>
      </template>

      <div
        class="primary rounded-0 d-flex flex-column px-8 pt-8 pb-6 align-center"
      >
        <span class="white--text font-weight-bold text-h6">{{
          $t("general.filters_label")
        }}</span>

        <div class="d-flex flex-column align-self-start mb-8">
          <v-checkbox
            v-for="(filter, i) in filters"
            :key="filter.label + i"
            v-model="filter.value"
            off-icon="mdi-circle-outline"
            on-icon="mdi-record-circle-outline"
            hide-details
            color="white"
          >
            <template v-slot:label>
              <span class="white--text body-2">{{ filter.label }}</span>
            </template>
          </v-checkbox>
        </div>

        <v-btn color="secondary" class="rounded-pill" small width="50%">
          {{ $t("general.search") }}
          <v-icon color="white">mdi-magnify</v-icon></v-btn
        >
      </div>
    </v-menu>
  </v-card>
</template>

<script>
export default {
  data() {
    return {};
  },

  computed: {
    filters() {
      return [
        {
          label: this.$t("menu.locations"),
          value: null,
        },
        {
          label: this.$t("menu.pois"),
          value: null,
        },
        {
          label: this.$t("menu.routes"),
          value: null,
        },
      ];
    },
  },

  methods: {
    onSearch() {
      console.log("on search");
    },
  },
};
</script>

<style scoped lang="scss">
.v-menu__content {
  width: 100% !important;
  border-radius: 0px !important;
}
</style>