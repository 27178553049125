<template>
  <div>
    <!-- appbar -->
    <!-- To-do add shadow when scrolling down -->
    <v-app-bar color="secondary" height="75px" fixed app class="d-flex" flat elevation="4">
      <!-- Open Menu -->
      <div class="bar-item">
      <v-btn text @click.stop="drawer = !drawer" style="margin-right: auto; height: auto;">
        <div class="d-flex flex-column">
          <v-icon color="white">mdi-forwardburger</v-icon>
          <span class="white--text">{{$t("appbar.menu")}}</span> 
        </div>
      </v-btn>
      </div>

      <!-- Home/Logo -->
      <div class="bar-item">
        <img
          v-if="$route.path=='/'"
          class="logo"
          src="@/assets/logo.svg"
          alt=""
          style="width: 100px; height: 60px;"
        />
      <v-btn v-else icon @click="$router.push('/')" large>
        <v-icon color="white"
        >
          mdi-home
        </v-icon>
      </v-btn>
      </div>

      <!-- Change font size -->
      <div class="bar-item">
        <HelpButton style="margin-left: auto;"></HelpButton>
      </div>
    </v-app-bar>
  
    <!-- Navigation Drawer -->
    <!-- To-do: Close on swipe -->
    <v-navigation-drawer
      v-model="drawer"
      temporary
      app
      color="white"
      width="80%"
      @hook:updated="drawerMount"
    >
      <!-- Top -->
      <!-- To-do add shadow when scrolling down-->
      <template v-slot:prepend>
        <div :class="(scrollPosition===0) ? '' : 'topbar'">
          <div class="d-flex nav-header pa-2">
            <!-- Close -->
            <div class="bar-item">
              <v-btn text @click="drawer = !drawer" style="margin-right: auto;height: auto;">
                <div class="d-flex flex-column">
                  <v-icon color="accent">mdi-backburger</v-icon>
                  {{$t("menu.close")}}
                </div>
              </v-btn>
            </div>

            <!-- Logo -->
            <div class="bar-item" style="position: absolute; left: 50vw; margin-left: -50px;">
              <img
                src="@/assets/logo.svg"
                alt=""
                style="width: 100px; height: 60px;" 
              />
            </div>

            <div class="bar-item"></div>
          </div>
          <v-divider class="mx-2"></v-divider>
        </div>
      </template>
      
      <!-- Buttons -->
      <div v-scroll.self="onScroll" style="height:100%;overflow-y: auto;" id="scrollTarget" ref="scrollTarget">
        <div class="d-flex flex-column">
          <SizeControl style="margin-left: auto;" menu></SizeControl>
          <v-divider class="mx-2"></v-divider>
        </div>

        <div class="d-flex flex-column mt-3">
          <v-btn
            class="justify-start active text-wrap"
            v-for="(item, i) in menu"
            :key="i"
            :to="item.path"
            :ripple="false"
            text
            :disabled="!status.connected"
            @click.native="closeDrawer()"
            style="
              display: inline-block;
              height: auto;
              margin-bottom: 5px;
              padding: 8px;
            "
          >
            <v-icon color="white" size="25" class="accent rounded-circle mr-2 pa-1">{{
              item.icon
            }}</v-icon>

            <span class="black--text body-1 font-weight">{{ item.label }}</span>
          </v-btn>

          <v-btn
            v-if="!status.connected"
            class="mb-5 d-flex align-center justify-start active white--text"
            :to="'/saved-routes'"
            color="white"
            :ripple="false"
            plain
          >
            <v-icon size="25" class="accent rounded-circle mr-2 pa-1"
              >mdi-download</v-icon
            >

            <span class="black--text body-1 font-weight">Saved Routes</span>
          </v-btn>
        </div>
      </div>

      <!-- Search -->
      <!-- To-do add shadow when scrolling-->
      <template v-slot:append>
        <div class="d-flex flex-column" :class="scrollHeight && (scrollPosition < scrollHeight) ? 'botbar' : ''">
          <v-divider class="mx-2"></v-divider>
          <v-btn 
            class="ma-2 justify-center" 
            color="black"
            text
            @click="openSearchDialog()"
          >
            <v-icon class="rounded-circle" color="black">mdi-magnify</v-icon>
            {{$t("general.search")}}
          </v-btn>
        </div>
      </template>

      <v-dialog persistent fullscreen v-model="searchDialog.open">
        <SearchDialog @close="closeSearchDialog" />
      </v-dialog>
    </v-navigation-drawer>
  </div>
</template>

<script>
import SearchDialog from "../components/SearchDialog.vue";
import SizeControl from "./SizeControl.vue";
import { Network } from "@capacitor/network";
import HelpButton from './HelpButton.vue'

export default {
  props:{
    globalScroll : Number
  },

  components: {
    SearchDialog,
    SizeControl,
    HelpButton
  },

  async created() {
    try {
      this.status = await Network.getStatus();
    } catch (e) {
      console.log(e);
    }
  },

  data() {
    return {
      drawer: false,
      scrollHeight: null,
      scrollPosition: 0,
      globalScrollHeight:0,
      location: null,
      mini: false,
      status: false,
      searchDialog: {
        open: false,
      },

      filters: [
        {
          label: "Περιοχή",
          value: null,
        },
        {
          label: "Τοποθεσίες ενδιαφέροντος",
          value: null,
        },
        {
          label: "Διαδρομή",
          value: null,
        },
      ],
    };
  },

  computed: {
    menu() {
      return [
        {
          label: this.$t("menu.home"),
          icon: "mdi-home",
          path: "/",
        },

        {
          label: 'Grand Hotel Kalamata',
          icon: "mdi-bed",
          path: "/hotel",
        },

        {
          label: this.$t("menu.locations"),
          icon: "mdi-home-city",
          path: "/locations",
        },

        {
          label: this.$t("menu.routes"),
          icon: "mdi-map-marker-path",
          path: "/routes",
        },

        {
          label: this.$t("menu.events"),
          icon: "mdi-calendar",
          path: "/events",
        },

        {
          label: this.$t("menu.gastronomy"),
          icon: "mdi-pot-steam",
          path: "/gastronomy",
        },

        {
          label: this.$t("menu.health"),
          icon: "mdi-hospital",
          path: "/health",
        },

        {
          label: this.$t("menu.settings"),
          icon: "mdi-cog",
          path: "/settings",
        },

        {
          label: this.$t("menu.info"),
          icon: "mdi-information-variant",
          path: "/info",
        },
      ];
    },
  },

  methods: {
    onSearch() {
      console.log("onSearch");
    },

    openSearchDialog() {
      this.searchDialog.open = true;
    },

    closeSearchDialog() {
      this.searchDialog.open = false;
    },

    closeDrawer() {
      this.drawer = false
    },

    onScroll(e){
      this.scrollHeight = e.target.scrollHeight - e.target.clientHeight;
      this.scrollPosition = e.target.scrollTop;
    },

    drawerMount(){
      if(this.drawer && this.$refs['scrollTarget'])
        this.scrollHeight = this.$refs['scrollTarget'].scrollHeight - this.$refs['scrollTarget'].clientHeight;
    }
  },
};
</script>

<style scoped lang="scss">
.logo {
  filter: brightness(0) invert(1);
}
.logo-black {
  filter: brightness(0) invert(0);
}
.nav-header {
  height: 75px !important;
}

.v-btn--active > .v-btn__content .v-icon {
  color: var(--v-primary-base) !important;
  background-color: white !important;
}

.navigation-drawer-title {
  position: relative !important;
}

::v-deep .v-btn{
  padding-left:8px !important;
  padding-right: 8px !important;
}

::v-deep .v-toolbar__content {
  padding: 8px !important;
}

.v-btn__content { 
  color: white !important
}  

.bar-item {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center; 
}

.topbar{
  box-shadow: 0 .125rem .3125rem rgba(0,0,0,.26);
}

.botbar{
  box-shadow: 0 -.125rem .3125rem rgba(0,0,0,.26);
}
</style>