<template>
  <div style="width: 100%;">
    <!-- Main page -->
    <div :class="{'no-scroll':drawer}" class="absolute-content">
      <h1 class="mb-2 ml-2">{{$t("events.title")}}</h1>

      <div class="pa-2 d-flex flex-column">
        <div style="position: sticky;top:0;z-index: 1;" class="white mb-5 pt-2 topbar">
          <div class="d-flex flex-row align-center mx-4 my-2 flex-grow-1" style="column-gap:20px;">
            <span class="text-h6">{{$t("events.today")}}</span>

            <div class="d-flex flex-column align-center">
              <span>FRI</span>
              <span class="font-weight-bold text-h6">14/4</span>
            </div>

            <v-divider></v-divider>

            <div @click="toggleDrawer" class="mx-4">
              <v-icon>mdi-chevron-up</v-icon>
              {{$t("events.filters_label")}}
            </div>
          </div>
        </div>
        
        <!-- Events -->
        <div class="card mb-4" v-for="(event,i) in events" :key="i">
          <v-img
          aspect-ratio="16 / 9"
          min-height="200px"
          min-width="200px"
          :src="event.image"
          ></v-img>

          <div class="d-flex flex-column">
            <div class="d-flex flex-row justify-space-between">
              <span style="word-wrap: break-word;" class="text-decoration-underline"> 17:00-19:00</span>
              <div class="font-weight-bold mx-2" style="white-space: nowrap;">{{ event.price_euro }} €</div>
            </div>

            <span class="font-weight-bold text-h6">
              {{event.name}}
            </span>

            <div class="flex-row">
              <v-icon>mdi-map-marker</v-icon>
              <span class="font-weight-bold">{{getAreaName(event.location_id)}}</span>, 
              {{ event.venue }}       
            </div>
          </div>


          <div> <v-icon>{{getCategoryIcon(event.category_id)}}</v-icon>{{getCategoryName(event.category_id)}}</div>
          <v-btn text @click="$router.push(`/events/${event.id}`)"> {{$t("general.more")}} <v-icon>mdi-text-box</v-icon> </v-btn>
        </div>
      </div>
    </div>

    <router-view class="white" :key="$route.fullPath"></router-view>

    <!-- Filters select -->
    <transition name="slide">
    <div class="drawer white" v-if="drawer">
      <div class="d-flex flex-column pa-4" style="column-gap: 16px;height: 100%;">
        <div @click="toggleDrawer" class="mx-4 white d-flex flex-row align-self-end">
          <v-icon>mdi-chevron-down</v-icon>
          {{$t("events.filters_label")}}
        </div>

        <div class="d-flex flex-column">
          <h3>{{$t("events.category")}}</h3>
          <v-divider></v-divider>

          <div>{{$t("events.category_select")}}</div>
          <div>
            <v-checkbox v-for="(category,i) in event_categories" :key="i" color="black black--text" hide-details="auto" height="36px" :input-value="true"
            v-model="selected_categories"
            :value="category.id"
            >
              <template v-slot:label>
                <span class="black--text">{{category.name}} <v-icon>{{ category.icon }}</v-icon></span>
              </template>
            </v-checkbox>
          </div>
        </div>

        <div class="d-flex flex-row align-self-end mt-auto pa-2" style="column-gap:10px">
          <div class="align-self-end"><v-btn text class="red white--text" @click="closeDrawer">{{$t("general.cancel")}}</v-btn></div>
          <div class="align-self-end"><v-btn text class="green white--text" @click="applyFilters">{{$t("general.apply")}}</v-btn></div>
        </div>
      </div>
    </div>
    </transition>
  </div>
</template>

<script>
import { mapActions,mapState,mapMutations } from 'vuex';
import store from "../../store";

export default {
  async created(){
    await this.getAreas();
    await this.getEventCategories();
    await this.getEvents();

    if(this.filters.categories){
      this.selected_categories = this.filters.categories;
    }
    else{
      for(var i = 0; i < this.event_categories.length; i++){
        this.selected_categories.push(this.event_categories[i].id);
      }
    }
  },
  data (){
    return {
      drawer:false,
      selected_categories:[]
    }
  },
  computed:{
    ...mapState({
      mobileLang: (state) => state.base.mobileLang.code,
      event_categories: (state) => state.events.categories,
      events: (state) => state.events.events,
      filters: (state) => state.events.filters,
    })
  },
  methods:{
    ...mapMutations({setFilters:"events/setFilters"}),
    ...mapActions({getAreas:"getAreas",getEventCategories:"events/getCategories",getEvents:"events/getEvents"}),
    toggleDrawer(){
      this.drawer = !this.drawer;
    },
    applyFilters(){
      this.setFilters({categories:this.selected_categories});
      this.getEvents();
      this.drawer=!this.drawer;
    },
    closeDrawer(){
      if(this.filters.categories){
        this.selected_categories = this.filters.categories;
      }
      else{
        for(var i = 0; i < this.event_categories.length; i++){
          this.selected_categories.push(this.event_categories[i].id);
        }
      }
      this.drawer=!this.drawer;
    },
    getCategoryIcon(category_id){
      const category = this.event_categories.filter(category => {
        return category.id === category_id;
      })[0]
      return category.icon
    },
    getCategoryName(category_id){
      const category = this.event_categories.filter(category => {
        return category.id === category_id;
      })[0]
      return category.name
    },
    getAreaName(area_id){
      const category = this.$store.state.areas.areas.filter(area => {
        return area.id === area_id;
      })[0]
      return category.name;
    }
  },
  beforeRouteEnter(to,from,next) {
    var parent_to = to.fullPath.split("/")[1]
    var parent_from = from.fullPath.split("/")[1]

    if(parent_to != parent_from){
      store.commit('events/setFilters',{});
    }
    next();
  },
};
</script>

<style scoped lang="scss">
.no-scroll{
  overflow: hidden;
}

.card{
  outline: 1px solid;
  border-radius: 6px;
  padding: 16px;
  margin-left: 16px;
  margin-right: 16px;
  display:flex;
  flex-direction: column;
  row-gap:20px;
  align-content: center;
}

.drawer {
  position: fixed;
  bottom: 0;
  top: 75px;
  width: 100%;
  z-index: 1;
  overflow: auto;
}

.slide-leave-active,
.slide-enter-active {
  transition: 0.2s;
}
.slide-enter {
  transform: translateY(100%);
}
.slide-leave-to {
  transform: translateY(100%);
}

.topbar{
  box-shadow: 0 .125rem .3125rem rgba(0,0,0,.26);
}
</style>