var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[(_vm.loader)?_c('div',{staticClass:"d-flex flex-column align-center justify-center flex-grow-1",staticStyle:{"height":"500px"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"60"}})],1):_c('div',[_c('l-map',{ref:"map",staticClass:"map",attrs:{"zoom":_vm.pois.length == 1 ?
        _vm.zoom
        :
        parseInt(_vm.area.zoom),"center":_vm.pois.length == 1 ?
        [parseFloat(_vm.pois[0].latitude), parseFloat(_vm.pois[0].longitude)]
        :
        [parseFloat(_vm.area.latitude), parseFloat(_vm.area.longitude)],"options":{ 
        zoomControl: false ,
        minZoom: 2,
        maxBounds: _vm.bounds,
        maxBoundsViscosity: 1.0
      }}},[_c('l-tile-layer',{attrs:{"url":_vm.url,"attribution":_vm.attribution,"detectRetina":true}}),(_vm.$route.path.split('/')[4] != 'map')?_c('l-control',{attrs:{"position":"topright"}},[_c('div',{staticClass:"secondary rounded-pill px-2 d-flex align-center",staticStyle:{"padding":"0 !important"},on:{"click":function($event){return _vm.openDialog(_vm.pois[0])}}},[_c('v-btn',{attrs:{"text":""}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-text-box-multiple")]),_c('span',{staticClass:"body-2 white--text pr-1"},[_vm._v(" "+_vm._s(_vm.$t("general.information"))+" ")])],1)],1)]):_vm._e(),_c('l-control',{attrs:{"position":"topleft"}},[_c('div',{staticClass:"secondary rounded-pill d-flex align-center",on:{"click":function($event){_vm.$route.path.split('/')[4] == 'map'
              ? _vm.$router.push(("/locations/area/" + (_vm.currentArea.id)))
              : _vm.$router.back()}}},[_c('v-btn',{attrs:{"text":""}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-arrow-left-thin")]),_c('span',{staticClass:"body-2 white--text pr-1"},[_vm._v(" "+_vm._s(_vm.$route.path.split("/")[4] == "map" ? _vm.currentArea.name : _vm.$t("pois.title"))+" ")])],1)],1)]),_vm._l((_vm.pois),function(poi,i){return _c('l-marker',{key:i,attrs:{"lat-lng":[parseFloat(poi.latitude), parseFloat(poi.longitude)],"draggable":false,"zIndexOffset":-1},on:{"click":function($event){return _vm.openDialog(poi)}}})})],2),(_vm.dialog.open)?_c('PoiDialog',{staticClass:"dialog",attrs:{"ppoi":_vm.dialog.poi},on:{"close":_vm.closeDialog}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }