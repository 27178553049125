<template>
  <div class="elevation-1 secondary pa-2">
    <v-container>
      <v-row justify="center" align="center">
        <v-col
          v-for="(action, i) in actions"
          :key="i"
          cols="6"
          class="card d-flex flex-column"
          @click="actionSelect(action)"
        >
          <div class="card-2 d-flex flex-column align-center justify-center">
            <v-icon color="white" size="45"> {{ action.icon }}</v-icon>
            <span class="white--text body-1 text-align-center">
              {{ action.label }}
            </span>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  computed: {
    actions() {
      return [
        {
          id: "1",
          icon: "mdi-directions",
          label: this.$t("routes.directions_text"),
        },

        {
          id: "2",
          icon: "mdi-flag-triangle",
          label: this.$t("routes.start_text"),
        },
      ];
    },
  },

  methods: {
    actionSelect(action) {
      if (action.id == 1) this.$emit("startRoute", true);
      else {
        this.$emit("startRoute", false);
        this.$emit("close");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.card {
  height: 150px !important;
  width: 100px !important;
}

.card-2 {
  height: 100% !important;
  width: 100% !important;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 8px;
}

.card-2:hover {
  transition: 0.3s ease;
  background: rgba(255, 255, 255, 0.3);
}
</style>