import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from '../views/Home/Home.vue';

import Locations from '../views/Locations/Locations.vue';
import areaRoutes from './area.js';

import RouteCategories from '../views/Routes/Categories.vue';
import RouteResults from '../views/Routes/Results.vue';
import Route from '../views/Routes/placeholderRoute.vue'

// import Pois from '../views/Pois/Pois.vue'
// import Poi from '../views/Pois/Poi.vue'

// import Routes from '../views/Routes/Routes.vue';
// import Route from '../views/Routes/Route.vue';

import Events from '../views/Events/Events.vue';
import Event from '../views/Events/Event.vue';



import Gastronomy from '../views/Gastronomy/Gastronomy.vue';
import GastronomyCategories from '../views/Gastronomy/Categories.vue';

import HealthCategories from '../views/Health/Categories.vue'
import HealthResults from '../views/Health/Results.vue'
import HealthEntry from '../views/Health/Entry.vue'

import Hotel from '../views/Hotel/Hotel.vue';

import Settings from '../views/Settings/Settings.vue';
import Info from '../views/Info/Info.vue'

import TermsAndPolicies from '../views/Info/TermsAndPolicies.vue';
import About from '../views/Info/About.vue';
import Contact from '../views/Info/Contact.vue';

import Videos from '../views/Videos/Videos.vue';

import Login from '../views/Auth/Login.vue';
import Register from '../views/Auth/Register.vue';
import ResetPassword from '../views/Auth/ResetPassword.vue';
import ChangePassword from '../views/Auth/ChangePassword.vue';

import SavedRoutes from '../views/SavedRoutes/SavedRoutes.vue'

import Setup from "../views/Setup/Setup.vue"

import store from "../store"

Vue.use(VueRouter);

const routes = [
  {
    path: '/setup',
    component: Setup,
  },

  {
    path: '/',
    component: Home,
  },

  {
    path: '/locations',
    component: Locations,
  },

  // {
  //   path: '/pois',
  //   component: Categories,
  //   props: { next_link: '/pois/results' , type: 'pois' }
  // },

  // {
  //   path: '/pois/results',
  //   component: Pois,
  // },

  // {
  //   path: '/pois/results/:poi_id',
  //   component: Poi,
  // },

  {
    path: '/routes',
    component: RouteCategories,
    props: { type: 'routes' }
  },

  {
    path: '/routes/results',
    component: RouteResults,
    props: { type: 'routes' }
  },

  {
    path: '/routes/:route_id',
    component: Route,
  },

  {
    path:'/events',
    component: Events,
    children: [
      {
        path:':event_id',
        component:Event
      }
    ]
  },

  {
    path: '/gastronomy',
    component: GastronomyCategories,
  },

  {
    path: '/gastronomy/:category_id',
    component: Gastronomy,
  },

  {
    path:'/health',
    component: HealthCategories,
  },

  {
    path:'/health/results',
    component: HealthResults,
    name: 'health_results'
  },

  {
    path:'/health/:entry_id',
    component: HealthEntry,
  },


  {
    path: '/hotel',
    component: Hotel,
  },

  {
    path: '/settings',
    component: Settings,
  },

  {
    path: '/info',
    component: Info,
  },

  {
    path: '/info/terms_and_policies',
    component: TermsAndPolicies,
  },

  {
    path: '/info/about',
    component: About,
  },

  {
    path: '/info/contact',
    component: Contact,
  },

  {
    path: '/login',
    component: Login,
  },

  {
    path: '/register',
    component: Register,
  },

  {
    path: '/reset_password',
    component: ResetPassword,
  },

  {
    path: '/change_password/:token',
    component: ChangePassword,
  },

  {
    path: '/videos',
    component: Videos,
  },

  {
    path: '/saved-routes',
    component: SavedRoutes
  },
  ...areaRoutes,
];

const router = new VueRouter({
  mode: 'history',
  // scrollBehavior(_to,_from,_savedPosition) {
  //   return { x: 0, y: 0 }
  // },
  base: process.env.BASE_URL,
  routes,
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => {
    if (err.name != 'NavigationDuplicated') {
      throw err;
    }
  });
};

router.beforeEach((to, from, next) => {
  if(to.path!='/setup'  && !store.state.base.setUp){
    next({ path: '/setup', replace: true });
  }
  if(to.path=='/setup' && store.state.base.setUp){
    next({ path: '/', replace: true });
  }
  next();
})

export default router;
