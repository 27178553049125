<template>
  <div class="component-wrapper d-flex flex-column secondary pa-3" style="overflow-x: hidden;">
    <div class="d-flex flex-row">
      <SizeControl></SizeControl>
    </div>

    <v-divider class="my-3" style="width: 100% !important"></v-divider>

    <div class="d-flex flex-row">
      <v-checkbox color="white" class="px-4 py-0 ma-0" hide-details="auto" height="36px" input-value="true">
        <span slot="label" class="white--text">{{ $t("settings.button_label") }}</span>
      </v-checkbox>
    </div>

    <v-divider class="my-3" style="width: 100% !important"></v-divider>

    <v-btn text @click="openDialog" class="justify-start" style="height: auto;">
      <div class="d-flex flex-column align-start">
        <span text class="text-h6 white--text flex-grow-0 align-start">{{
          $t("settings.language")
        }}</span>
        <span v-if="language != null" class="body-2 grey--text">{{
          language.engName
        }}</span>
      </div>
    </v-btn>

    <v-divider class="my-3" style="width: 100% !important"></v-divider>

    <v-btn text class="justify-start py-2" style="height: auto;">
      <span text class="text-h6 white--text flex-grow-0 align-start">
        {{ $t("settings.manage_profile") }}
      </span>
    </v-btn>

    <v-dialog v-if="dialog.open" v-model="dialog.open">
      <v-card>
        <v-card-title>
          {{ $t("settings.language") }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-radio-group v-model="componentLang">
            <v-radio
              color="secondary"
              v-for="(lang, i) in languages"
              :key="i + lang.code"
              :value="lang"
              return-object
            >
              <template v-slot:label>
                <div class="d-flex flex-column jutify-start">
                  <span class="">{{ lang.engName }}</span>
                  <span class="caption">{{ lang.name }}</span>
                </div>
              </template>
            </v-radio>
          </v-radio-group>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn class="ml-auto" text color="secondary" @click="closeDialog">
            <v-icon>mdi-close</v-icon>{{ $t("general.cancel") }}
          </v-btn>
          <v-btn text color="secondary" @click="changeLang">
            <v-icon>mdi-check</v-icon>{{ $t("general.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

import SizeControl from "../../components/SizeControl.vue";
export default {
  data() {
    return {
      dialog: {
        open: false,
        tempLang: null,
      },
    };
  },
  components: {
    SizeControl
  },

  computed: {
    ...mapState({
      languages: (state) => state.base.languages,
      language: (state) => state.base.mobileLang,
    }),

    componentLang: {
      get() {
        return this.language;
      },
      set(newValue) {
        this.tempLang = newValue;
      },
    },
  },

  methods: {
    ...mapMutations(["setMobileLang"]),

    openDialog() {
      this.dialog.open = true;
    },

    closeDialog() {
      this.dialog.open = false;
    },

    changeLang() {
      if (this.tempLang != null) this.setMobileLang(this.tempLang);
      this.$i18n.locale = this.language.code;
      this.closeDialog();
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-input__slot {
  margin:0px;
}
</style>