<template>
  <div class="component-wrapper">
    <PageLoader v-if="pageLoader" />
    <div v-else class="d-flex flex-column" style="width: 100%;">
      <div class="mb-2 pa-2">
        <h2>{{ $t("locations.label") }}</h2>
        <h4>{{ $t("locations.select") }}</h4>
      </div>

      <AreaPreview
        v-for="(area, i) in areas"
        :key="area.name + i"
        :area="area"
        class="mb-1 align-self-center"
      />
    </div>
  </div>
</template>

<script>
import AreaPreview from "../../components/AreaPreview.vue";
import PageLoader from "../../components/PageLoader.vue";

import { mapState, mapActions } from "vuex";
export default {
  components: {
    AreaPreview,
    PageLoader,
  },

  async created() {
    // try {
    //   await this.getAreas();
    //   this.pageLoader = false;
    // } catch (e) {
    //   this.pageLoader = false;
    // }
    await this.getAreas();
    this.pageLoader = false;
  },

  data() {
    return {
      pageLoader: true,
    };
  },

  computed: {
    ...mapState({
      areas: (state) => state.areas.areas,
    }),
  },

  methods: {
    ...mapActions(["getAreas"]),
  },
};
</script>

<style scoped lang="scss">
</style>