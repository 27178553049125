<template>
  <div class="component-wraper pa-2 d-flex flex-column flex-grow-1">
    <div>
      <v-btn icon @click="$router.push('/info')">
        <v-icon color="primary">mdi-arrow-left</v-icon>
      </v-btn>
      <h1 class="secondary--text font-weight-bold text-h5">About</h1>
    </div>

    <v-divider class="mb-2"></v-divider>
    <div class="px-4">
      <p class="text-justify body-2">
        Basic Information
      </p>
      <div class="d-flex align-start mb-2" v-for="(text, i) in texts" :key="i">
        <v-icon size="10" color="black" class="mt-2 mr-2">mdi-circle</v-icon>
        <span class="body-2">{{ text }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      texts: [
        "Item 1",
        "Item 2",
        "Item 3",
        "Item 4",
        "Item 5",
        "Item 6",
      ],
    };
  },
};
</script>

<style>
</style>