<template>
  <v-overlay color="white" opacity="1">
    <div class="d-flex flex-column align-center">
      <span
        class="
          d-flex
          flex-column
          px-4
          mb-10
        "
      >
        <img
          src="@/assets/logo.svg"
          width="100%"
        />
      </span>

      <v-progress-circular
        size="100"
        indeterminate
        color="secondary"
      ></v-progress-circular>
    </div>
  </v-overlay>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.logo {
  filter: brightness(0) invert(1);
}
</style>