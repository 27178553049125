<template>
  <div class="component-wrapper d-flex flex-column pa-2">
    <v-btn icon @click="$router.push('/info')">
      <v-icon color="primary">mdi-arrow-left</v-icon>
    </v-btn>

    <div class="d-flex flex-column px-2 mb-2">
      <h1 class="secondary--text font-weight-bold text-h5 mb-2">Terms</h1>
      <v-divider class="mb-2"></v-divider>
      
      <div class="d-flex flex-column px-4" v-for="(term, i) in 5" :key="i">
        <h2 class="secondary--text font-weight-bold body-1">
          {{ i + 1 }}.Term
        </h2>

        <p class="text-justify body-2">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis vitae
          ex temporibus quod accusantium facilis? Tenetur vero quas aut
          deserunt! Similique accusantium nostrum molestias dignissimos
          perferendis quisquam earum veritatis temporibus.
        </p>
      </div>
    </div>
    <div class="d-flex flex-column px-2 mb-2">
      <h1 class="secondary--text font-weight-bold text-h5 mb-2">Policies</h1>
      <v-divider class="mb-2"></v-divider>

      <div class="d-flex flex-column px-4" v-for="(policy, i) in 5" :key="i">
        <h2 class="secondary--text font-weight-bold body-1">
          {{ i + 1 }}.Policy
        </h2>

        <p class="text-justify body-2">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis vitae
          ex temporibus quod accusantium facilis? Tenetur vero quas aut
          deserunt! Similique accusantium nostrum molestias dignissimos
          perferendis quisquam earum veritatis temporibus.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
</style>