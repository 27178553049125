<template>
  <div class="component-wrapper secondary">
    <h1 class="white--text font-weight-bold text-h5 px-2">
      {{ $t("videos.title") }}
    </h1>
    <span class="caption grey--text mb-2 px-2">
      {{ $t("videos.step1") }}
    </span>
    <div
      v-for="(video, i) in videos"
      :key="i + video"
      class="elevation-3 mt-4 d-flex flex-column justify-center secondary"
    >
      <youtube
        class="iframe mb-2"
        :video-id="videoId"
        :player-width="'100%'"
        player-height="250"
        :player-vars="{ autoplay: 0 }"
      ></youtube>

      <div class="body-1 px-2 mb-2 font-weight-bold white--text">
        {{ video }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      videoId: "AmYrtRMk2Oc",
      videos: [
        "Επιστροφή στην Ιθάκη του Οδυσσέα",
        "Κεφαλονιά - Η Επτανήσια Καλλονή",
        "Κέρκυρα - Η Αρχόντισσα του Ιονίου",
        "Η Ζάκυνθος και τα χελωνάκια Καρέτα-Καρέτα",
        "Η Λευκάδα και ο Μύθος του ανεκπλήρωτου Έρωτα",
        "Το Τρίγωνο Πολύγωνο της Βερμούδας",
        "Κάστρα, Φρούρια και Οχυρώσεις των Ιονίων Νήσων",
        "Επτανησιακή Γαστρονομία και Τοπική Παραγωγή",
        "Hiking & Biking στα Ιόνια Νησιά",
        "Μοναστήρια και Ιεροί Ναοί των Ιονίων Νήσων",
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.iframe {
  width: 100vw !important;
  height: 250px;
  aspect-ratio: 4/3 !important;
  padding: 0;
  margin: 0;
}
</style>