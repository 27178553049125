import axios from 'axios'
export default {
  namespaced: true,
  state: {
    currentRoute: null,
    routes: [],
    categories:[]
  },

  mutations: {
    setCurrentRoute(state, currentRoute) {
      state.currentRoute = currentRoute
    },
    setRoutes(state, routes) {
      state.routes = routes;
    },
    setCategories(state,categories){
      state.categories = categories;
    }
  },

  actions:{
    async getRoutes({commit, rootState}) {
      var routes=[];
      if(rootState.base.mobileLang){
        routes=require(`../../examples/routes_${rootState.base.mobileLang.code}.json`)
      }

      if(rootState.base.filters.area)
      {
        routes=routes.filter(route => {
          return route.area_id === rootState.base.filters.area;
        })
      }
      if(rootState.base.filters.category && rootState.base.filters.category.id){
        routes = routes.filter(route => {
          return route.category_id === rootState.base.filters.category.id;
        })
      }

      commit("setRoutes", routes);
    },

    async getCategories({commit,rootState}) {
      var categories=null;
      if(rootState.base.mobileLang){
        categories=require(`../../examples/categories_routes_${rootState.base.mobileLang.code}.json`);
      }
      commit("setCategories", categories);
    },

    getRoute({commit,rootState},route_id){
      commit("setFilters",{area:rootState.areas.currentArea ? rootState.areas.currentArea.id : null,category:rootState.base.filters.category},{root:true});
      var routes=[];
      if(rootState.base.mobileLang){
        routes=require(`../../examples/routes_${rootState.base.mobileLang.code}.json`)
      }
      const route = routes.filter(route => {
        return route.id === route_id
      })[0]
      commit("setCurrentRoute", route);
    }
  }
}