<template>
  <div class="component-wrapper secondary absolute-content d-flex flex-column">
    <v-btn class="return-button-sticky white secondary--text elevation-5 rounded-pill align-self-start" text @click="$router.push('/gastronomy')">
      <v-icon>mdi-arrow-left-thin</v-icon> {{ $t('gastronomy.title') }}
    </v-btn>

    <div class="body-1 grey--text my-2 px-2">
      <v-icon style="font-size:1.3rem" color="grey">{{ category.icon }}</v-icon> {{ category.name }}
    </div>

    <div class="pa-2">
      <!-- Items -->
      <v-card
        v-for="(product, i) in products"
        :key="i"
        width="100vw"
        color="secondary"
        class="mt-4"
      >
        <v-img
          height="250"
          :src="product.image"
        ></v-img>

        <v-card-title class="white--text">{{product.name}}</v-card-title>

        <v-card-actions>
          <v-btn text color="white">See More...</v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  async created() {
    try {
      if(this.$store.state.base.mobileLang){
        this.categories=require(`../../../examples/gastronomy_categories_${this.$store.state.base.mobileLang.code}.json`);
        this.products=require(`../../../examples/gastronomy_${this.$store.state.base.mobileLang.code}.json`);
      }

      this.category = this.categories.find(
        (el) => el.id === this.$route.params.category_id
      );
    } catch (e) {
      this.loader = false;
      console.log(e);
    }
  },
  data() {
    return {
      products: [
      ],
      categories: [
      ],
      category:{}
    };
  },
};
</script>

<style scoped lang="scss">
.iframe {
  width: 100vw !important;
  height: 250px;
  aspect-ratio: 4/3 !important;
  padding: 0;
  margin: 0;
}
</style>