<template>
  <div class="d-flex flex-column component-wrapper secondary results" :key="$route.fullPath">
    <!-- Return button -->
    <div
      class="white rounded-pill d-flex align-self-start return-button-sticky  elevation-5"
      @click="$router.push(`/locations/area/${$route.params.area_id}/${type}`)"
    >
      <v-btn text>
        <v-icon color="secondary">mdi-arrow-left-thin</v-icon>
        <span
          class="body-2 secondary--text pr-1"
        >
          {{$t('general.categories_label')}}
        </span>
      </v-btn>
    </div>

    <div
      class="
        d-flex
        flex-column
        px-2
      "
    >
      <!-- Header -->
      <span class="d-flex align-self-start white--text font-weight-bold text-h5">
        {{ $t(`${type}.title`) }}
      </span>
      <span class="caption grey--text mb-2 px-2">
       <v-icon style="font-size:1.3rem" color="grey">{{ filters.category.icon }}</v-icon> {{ filters.category.name }}
      </span>

      <!-- Loader -->
      <div
        v-if="loader"
        class="d-flex flex-column align-center flex-grow-1"
        style="height: 500px"
      >
        <v-progress-circular indeterminate color="white" size="60">
        </v-progress-circular>
      </div>

      <!-- Results -->
      <div
        v-else-if="!loader && content.length>0"
        v-for="(entry, i) in content"
        :key="i"
        class="d-flex flex-column px-2"
        style="width: 100%"
      >
        <div
          @click="openEntry(entry)"
          class="
            d-flex
            justify-start
            align-center
            white--text
            route
            font-weight-thin
            body-1
            mb-2
          "
        >
          <span class="mr-auto">{{ entry.name }}</span>
          <v-btn icon color="white">
            <v-icon> mdi-arrow-right</v-icon>
          </v-btn>
        </div>

        <v-divider
          v-if="i != content.length - 1"
          class="mb-2"
          style="width: 100% !important"
          color="white"
        ></v-divider>
      </div>

      <span v-else-if="!loader && content.length==0" class="white--text px-2">{{$t(`${type}.not_found`)}}</span>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapMutations,mapActions,mapState } from "vuex";

export default {
  async created() {
    // try {
    //   const res = await axios.get(
    //     `${process.env.VUE_APP_BASE_URL}/areas/${this.$route.params.area_id}/pois`
    //   );
    //   this.pois = res.data.pois;
    //   this.loader = false;
    // } catch (e) {
    //   throw e;
    // }

    if(this.type=="pois"){
      this.getPois();
      this.content = this.pois;
    }
    else if(this.type=="routes"){
      this.getRoutes();
      this.content = this.routes;
    }

    this.loader=false;
  },

  computed: {
    ...mapState({
      pois: (state) => state.pois.pois,
      routes: (state) => state.routes.routes,
      filters: (state) =>state.base.filters
    }),
  },

  data() {
    return {
      loader: true,
      content: [],
    }
  },
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  methods: {
    ...mapActions({getPois:"pois/getPois",getRoutes:"routes/getRoutes"}),
    openEntry(entry){
      if(this.type=="pois"){
        this.$router.push(`${entry.id}/map`);
      }
      else if(this.type=="routes"){
        this.$router.push(`${entry.id}`);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.results {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 0;
  overflow-y: auto;
}

.user-routes {
  top: 50vh; //suggested-routes height
  width: 100vw;
  height: calc(100% - 50vh);
  position: absolute;
  overflow-y: auto !important;
}

.route {
  width: 100%;
}

.return-button{
  margin-left: 10px; 
  margin-top: 10px;
  position:sticky;
  top:10px;
  left:0;
  z-index: 1;
}
</style>