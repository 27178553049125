export default {
  namespaced: true,
  state: {
    events: [],
    categories:[],
    filters:{},
    currentEvent:null
  },

  mutations: {
    setEvents(state, events) {
      state.events = events;
    },
    setCategories(state,categories){
      state.categories = categories;
    },
    setFilters(state, filters) {
      state.filters = filters
    },
    setCurrentEvent(state,event) {
      state.currentEvent = event;
    }
  },
  
  actions:{
    async getEvents({commit, rootState,state}) {
      var events=[];
      if(rootState.base.mobileLang){
        events=require(`../../examples/events_${rootState.base.mobileLang.code}.json`)
      }

      if(state.filters.categories){
        events = events.filter(event => {
          for(var i = 0; i < state.filters.categories.length; i++){
            if(event.category_id === state.filters.categories[i]) return true;
          }
          return false;
        })
      }

      commit("setEvents", events);
    },

    async getCategories({commit,rootState}) {
      var categories=null;
      if(rootState.base.mobileLang){
        categories=require(`../../examples/categories_events_${rootState.base.mobileLang.code}.json`);
      }
      commit("setCategories", categories);
    },

    getPoi({commit,rootState,dispatch},poi_id){
      commit("setFilters",{area:rootState.areas.currentArea.id,category:rootState.base.filters.category},{root:true});
      var pois=[];
      if(rootState.base.mobileLang){
        pois=require(`../../examples/pois_${rootState.base.mobileLang.code}.json`)
      }
      pois = pois.filter(poi => {
        return poi.id === poi_id
      })
      commit("setPois", pois);
    },

    getEvent({commit,rootState},event_id){
      var events=[];
      if(rootState.base.mobileLang){
        events=require(`../../examples/events_${rootState.base.mobileLang.code}.json`)
      }
      const event = events.filter(event => {
        return event.id === event_id
      })[0]
      commit("setCurrentEvent", event);
    }
  }
}