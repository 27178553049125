<template>
  <v-btn 
    class="ma-2 justify-start text-wrap py-2" 
    color="black"
    text
    @click="changeSize()"
    v-if="menu"
    style="display:inline-block; height: auto;word-wrap: break-word;"
  >
    <v-icon color="black">mdi-format-size</v-icon>
    <span class="black--text">{{$t("settings.font_size")}}</span>
    <span class="ml-1 black--text font-weight-bold">{{ selected }}</span>
  </v-btn>

  <div v-else class="flex-grow-1">
    <v-btn 
      text
      @click="changeSize()"
      width="100%"
      class="justify-start py-2 text-wrap"
      style="display:inline-block; height: auto;word-wrap: break-word;"
    >
      <v-icon color="white">mdi-format-size</v-icon>
      <span class="text-h6 white--text">{{$t("settings.font_size")}}</span>
      <span class="ml-1 text-h6 white--text font-weight-bold">{{ selected }}</span>
    </v-btn>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default{
  data () {
    return {
      selected: 1,
      font_sizes: ['16px','20px','24px'],
      icon_sizes: ['25px','28px','31px'],
    }
  },
  props: {
    menu: Boolean
  },
  methods:{
    ...mapMutations(["changeUISize"]),
    changeSize() {
      this.selected++;
      if (this.selected>this.font_sizes.length){
        this.selected = 1;
      }

      this.changeUISize({fontSize:this.font_sizes[this.selected-1],iconSize:this.icon_sizes[this.selected-1]});
    }
  }
}
</script>