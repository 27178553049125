<template>
  <div>
    <div
      class="
        component-wrapper
        suggested-routes
        secondary
        d-flex
        flex-column
        align-center
        pa-4
      "
    >
      <div class="d-flex align-self-start mb-2" :style="'width:100%'">
        <span class="white--text font-weight-bold text-h6">
          {{ $t("routes.title") }}
        </span>

        <v-btn
          icon
          color="white"
          class="ml-auto"
          @click="$router.push(`/locations/area/${$route.params.area_id}`)"
        >
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </div>
      <div
        v-for="(route, i) in routes"
        :key="i"
        class="d-flex flex-column"
        style="width: 100%"
      >
        <div
          v-if="loader"
          class="d-flex flex-column align-center flex-grow-1"
          style="height: 500px"
        >
          <v-progress-circular indeterminate color="white" size="60">
          </v-progress-circular>
        </div>

        <div
          v-else
          @click="$router.push(`${$route.path}/${route.id}`)"
          class="
            d-flex
            justify-start
            align-center
            white--text
            route
            font-weight-thin
            body-1
            mb-2
          "
        >
          <span class="mr-auto">{{ route.name }}</span>
          <v-btn icon color="white">
            <v-icon> mdi-arrow-right</v-icon>
          </v-btn>
        </div>

        <v-divider
          v-if="i != routes.length - 1"
          class="mb-2"
          style="width: 100% !important"
          color="white"
        ></v-divider>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  async created() {
    try {
      const res = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/areas/${this.$route.params.area_id}/paths`
      );
      this.routes = res.data.paths;
      this.loader = false;
    } catch (e) {
      throw e;
    }
  },

  data() {
    return {
      loader: true,
      routes: [],
    };
  },
};
</script>

<style scoped lang="scss">
.suggested-routes {
  top: 0px;
  height: 50vh;
  width: 100vw;
  position: absolute;
  // flex: 1 1 1px; /* new */
  overflow-y: auto !important;
  height: 100%;
}

.route {
  width: 100%;
}
</style>