<template>
  <div class="d-flex flex-column align-center black--text px-5" style="width: 100%;">
    <!-- Top -->
    <!-- Back Button -->
    <div class="white d-flex flex-row top">
      <v-btn :disabled="step===1" @click="previousStep()" text class="mr-auto">
        <v-icon color="black">mdi-arrow-left</v-icon>
        {{$t('setup.previous')}}
      </v-btn>
    </div>
    <!-- Top -->

    <!-- Content -->
    <!-- Welcome/Language -->
    <div v-if="step===1" class="d-flex flex-column align-center" style="margin-top: auto; margin-bottom: auto;">
      <img
        src="@/assets/logo.svg"
        width="70%"
        class="mb-6"
      />

      <span class="text-center mb-6 text-h5 ">{{$t('setup.welcome')}}</span>

      <div class="setting elevation-1">
        <v-icon>mdi-translate</v-icon> {{$t('setup.language')}}
        <div class="ml-8 caption" v-if="language.code!='en'">Please select you language:</div>

        <v-select
          v-model="language"
          :items="languages"
          item-value="engName"
          label="Select"
          single-line
          return-object
          style="width: 100%;"
          @change="changeLang"
        >
          <template v-slot:selection="data">
            <!-- HTML that describe how select should render selected items -->
            <div class="d-flex flex-column jutify-start">
              <span class="">{{ data.item.engName }}</span>
              <span class="caption">{{ data.item.name }}</span>
            </div>
          </template>
          <template v-slot:item="data">
            <div class="d-flex flex-column jutify-start">
              <span class="">{{ data.item.engName }}</span>
              <span class="caption">{{ data.item.name }}</span>
            </div>
          </template>
        </v-select>
      </div>
    </div>

    <!-- User Info -->
    <div v-if="step===2" class="d-flex flex-column align-center" style="margin-top: auto; margin-bottom: auto;row-gap: 10px;">
      <div class="mb-10 text-h5">
        {{ $t('setup.profile') }}
      </div>

      <div class="d-flex flex-column setting elevation-1" >
        {{ $t('setup.font') }}
        <SizeControl menu></SizeControl>
      </div>

      <v-divider style="background-color: black;"></v-divider>

      <div class="d-flex flex-column setting elevation-1">
        {{ $t('setup.transportation') }}
        <div>
          <v-checkbox v-for="(mode,i) in transport_modes" :key="i" color="black black--text" hide-details="auto" height="36px" :input-value="false"
          >
            <template v-slot:label>
              <span class="black--text">{{mode.name}} <v-icon>{{ mode.icon }}</v-icon></span>
            </template>
          </v-checkbox>
        </div>
      </div>

      <div class="d-flex flex-column setting elevation-1">
        {{ $t('setup.food') }}
        <div>
          <v-checkbox v-for="(habit,i) in dietary_habits" :key="i" color="black black--text" hide-details="auto" height="36px" :input-value="false"
          >
            <template v-slot:label>
              <span class="black--text">{{habit.name}} <v-icon>{{ habit.icon }}</v-icon></span>
            </template>
          </v-checkbox>
        </div>
      </div>

      <div class="d-flex flex-column setting elevation-1">
        <div>
          <v-icon>mdi-human</v-icon> {{ $t('setup.accessibility') }}
        </div>

        <v-radio-group v-model="radioGroup">
          <v-radio color="black black--text" :key="1"  :value="1">
            <template v-slot:label>
              <span class="black--text">I am healthy/independent</span>
            </template>
          ></v-radio>
          <v-radio color="black black--text" :key="2" :value="2">
            <template v-slot:label>
              <span class="black--text" v-if="!(radioGroup===2)">I need some support</span>
              <span class="black--text" v-else>I need some support :</span>
            </template>
          ></v-radio>
        </v-radio-group>

        <div class="ml-2" v-if="radioGroup===2">
          <v-checkbox v-for="(option,i) in accessibility_options" :key="i" color="black black--text" hide-details="auto" height="36px" :input-value="false"
          >
            <template v-slot:label>
              <span class="black--text">{{option.name}} <v-icon>{{ option.icon }}</v-icon></span>
            </template>
          </v-checkbox>
        </div>
      </div>
    </div>
    
    <div v-if="step===3" style="margin-top: auto; margin-bottom: auto;">
      <span class="text-center"> {{ $t('setup.hotel') }}</span>

      <v-form class="d-flex flex-row align-center flex-wrap" @submit.prevent="onSubmit">
        <v-text-field
        solo
        type="text"
        :label="$t('setup.hotel_code')"
        light
        hide-details="auto"
        >
        </v-text-field>
      </v-form>
    </div>
    <!-- Content -->

    <!-- Bottom -->
    <div class="d-flex flex-row align-center white bottom mt-4">
      <!-- Steps -->
      <div class="d-flex flex-row align-center justify-center">
        <div v-for="i in 3" :key="i" class="d-flex flex-row align-center justify-center">
          <div class="circle d-flex justify-center align-center" :class="{'disabled':i<step,'pulse-circle':i===step}">{{ i }}</div> <div v-if="i<3" class="dot mx-2" :class="{'disabled':i<step}"></div>
        </div>
      </div>
      
      <!-- Button -->
      <v-btn color="green ml-auto" @click="nextStep()" v-if="step<3">
        <span class="white--text">{{$t('setup.next')}}</span> 
        <v-icon color="white">mdi-chevron-right</v-icon>
      </v-btn>

      <v-btn color="green ml-auto" @click="onSubmit()" v-else>
        <span class="white--text">{{$t('setup.submit')}}</span>
        <v-icon color="white">mdi-chevron-right</v-icon>
      </v-btn>
    </div>
    <!-- Bottom -->
  </div>
</template>

<script>
import {mapMutations,mapState} from "vuex";
import SizeControl from "../../components/SizeControl.vue";

export default {
  components:{
    SizeControl
  },
  data(){
    return{
      step: 1,
      radioGroup: 1,
      transport_modes:[
        {
          name:"Car",
          icon:"mdi-car-hatchback"
        },
        {
          name:"Motorcycle",
          icon:"mdi-motorbike"
        },
        {
          name:"Bike",
          icon:"mdi-bike"
        },
        {
          name:"Bus",
          icon:"mdi-bus-side"
        },
        {
          name:"Other"
        },
      ],
      dietary_habits:[
        {
          name:"Vegetarian"
        },
        {
          name:"Vegan"
        },
        {
          name:"Lactose-Free"
        },
        {
          name:"Gluten-Free"
        },
        {
          name:"Sugar-Free"
        },
      ],
      accessibility_options:[
        {
          name:"I have mobility limitations",
          icon: "mdi-walk"
        },
        {
          name:"I use a wheelchair",
          icon:"mdi-wheelchair-accessibility"
        },
        {
          name:"I have hearing problems",
          icon:"mdi-ear-hearing"
        },
        {
          name:"I have vision problems",
          icon:"mdi-eye"
        }
      ]
    }
  },
  methods: {
    ...mapMutations(["setup","setMobileLang"]),
    onSubmit() {
      this.setup();
      this.$router.push({path:'/',replace: true});
    },
    nextStep() {
      if(this.step<3){
        this.step += 1;
      }
    },
    previousStep() {
      if(this.step>1){
        this.step -= 1;
      }
    },
    changeLang() {
      this.$i18n.locale = this.language.code;
    },
  },
  computed:{
    ...mapState({
      languages: (state) => state.base.languages,
    }),
    language: {
      get(){
        return this.$store.state.base.mobileLang;
      },
      set(value){
        this.setMobileLang(value);
      }
    },
  }
}
</script>

<style scoped lang="scss">
.circle{
  height: 25px;
  width: 25px;
  font-size: 15px;
  background-color: #bbb;
  border-radius: 50%;
}

.dot{
  height: 6px;
  width: 6px;
  background-color: #bbb;
  border-radius: 50%;
}

.pulse-circle {
  -webkit-animation: pulse 1.5s infinite;
  box-shadow: 0 0 0 0 rgba(#bbb, 0.5);
}

@-webkit-keyframes pulse {
  0% {
    // transform: scale(1);
  }
  70% {
    // transform: scale(1.05);
    box-shadow: 0 0 0 10px rgba(90, 153, 212, 0);
  }
  100% {
    // transform: scale(1);
    box-shadow: 0 0 0 0 rgba(90, 153, 212, 0);
  }
}

.circle.disabled{
  opacity: 0.5;
}

.setting{
  background-color: rgba(0,0,0,.1); 
  padding: 12px; 
  border-radius: 4px;
  width: 100%;
}

.top{
  position: sticky;
  top: 0;
  padding: 12px;
  z-index: 1;
  width: 100vw;
}

.bottom{
  position: sticky;
  bottom:0;
  padding: 12px;
  z-index: 1;
  width: 100vw;
}
</style>