import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#79bac9',
        secondary: '#42828c',
        accent: '#79bac9',
        highlight: '#ff9b30',
        error: '#FF5252',
        info: '#2196F3',
        success: '#8bc541',
        warning: '#FFC107',
        grey: '#e7e7e7',
        darkgrey: '#808080',
        lightgray: '#AEAEAE'
      },
    },
  },
});
