<template>
  <div class="component-wrapper d-flex flex-column align-center">
    <div
      v-if="loader"
      class="d-flex flex-column align-center justify-center flex-grow-1"
      style="height: 500px"
    >
      <v-progress-circular indeterminate color="primary" size="60">
      </v-progress-circular>
    </div>

    <div v-else>
      <!-- map -->
      <!-- map -->
      <!-- map -->
      <div class="content">
        <div class="content-title">
          <l-map
            class="map"
            ref="map"
            style="width: 100vw; z-index: 0; margin-top: 80px"
            :style="
              $route.path.split('/')[1] == 'routes'
                ? 'height:75vh'
                : 'height: 70vh'
            "
            :zoom="zoom"
            :center="route.polyline[0]"
            :options="{
              zoomControl: false,
              minZoom: 2,
              maxBounds: bounds,
              maxBoundsViscosity: 1.0
            }"
          >
            <l-tile-layer
              :url="url"
              :attribution="attribution"
              :detectRetina="true"
            ></l-tile-layer>

            <l-polyline
              :lat-lngs="route.polyline"
              color="var(--v-primary-base)"
            ></l-polyline>

            <l-control position="topleft">
              <div
                class="secondary rounded-pill px-2 d-flex align-center"
                @click="
                  $route.path.split('/')[1] == 'routes'
                    ? $router.go(-1)
                    : $router.push(`/locations/area/${currentArea.id}`)
                "
              >
                <v-btn icon>
                  <v-icon color="white">mdi-arrow-left-thin</v-icon>
                </v-btn>
                <span class="body-2 white--text pr-1">{{
                  $route.path.split("/")[1] == "routes"
                    ? $t("routes.title")
                    : currentArea.name
                }}</span>
              </div>
            </l-control>

            <l-control position="topright">
              <v-btn
                icon
                class="pa-6 secondary rounded-circle mb-2"
                :class="!start ? 'pulse-button-start' : ''"
                @click="openDialog(null, 'start_route_dialog')"
              >
                <v-icon color="white" size="35">mdi-flag-triangle</v-icon>
              </v-btn>
              <div class="d-flex flex-column align-center">
                <v-btn
                  icon
                  class="secondary mb-2 white--text"
                  @click="getLocation"
                  :loading="locationLoader"
                >
                  <v-icon color="white">mdi-crosshairs-gps</v-icon>
                </v-btn>
              </div>
            </l-control>

            <l-marker
              @click="openDialog(poi.poi, 'poi_dialog')"
              v-for="(poi, i) in route.path_pois"
              :key="i"
              :lat-lng="[
                parseFloat(poi.poi.latitude),
                parseFloat(poi.poi.longitude),
              ]"
              :draggable="false"
            ></l-marker>

            <custom-marker
              :marker="{ lat: location.latitude, lng: location.longitude }"
              v-if="hasLocation"
            >
              <v-icon
                color="secondary"
                class="rounded-circle pulse-button"
                size="15"
                >mdi-circle</v-icon
              >
            </custom-marker>
          </l-map>
        </div>
      </div>

      <!-- map -->
      <!-- map -->
      <!-- map -->

      <!-- Route details -->
      <!-- Route details -->
      <!-- Route details -->
      <!-- Route details -->

      <div
        class="page-body"
        :style="$route.path.split('/')[1] == 'routes' ? 'top:77%' : 'top: 72%'"
      >
        <v-btn
          icon
          class="white rounded-0 chevron-button px-6"
          @click="$vuetify.goTo(500, options)"
        >
          <v-icon class="px-2" color="primary darken-1" size="30"
            >mdi-chevron-up</v-icon
          >
        </v-btn>
        <div class="d-flex flex-column px-4 py-2 white">
          <div class="d-flex align-center justify-space-between mb-2">
            <span
              class="primary--text text--darken-2 font-weight-regular text-h6"
            >
              {{ route.name }}
            </span>

            <div class="d-flex align-center">
              <v-btn icon @click="play">
                <v-icon color="white" class="primary rounded-circle" size="20">
                  mdi-play
                </v-icon>
              </v-btn>

              <v-btn icon @click="stop" :disabled="!playing">
                <v-icon
                  color="white"
                  class="rounded-circle"
                  size="20"
                  :class="playing ? 'primary' : 'grey'"
                >
                  mdi-stop
                </v-icon>
              </v-btn>
            </div>
          </div>

          <v-divider class="mb-4"></v-divider>

          <!-- poi change -->
          <div
            class="d-flex align-center mb-4"
            :style="start ? '' : 'pointer-events: none;opacity: 0.4'"
          >
            <v-btn
              icon
              :disabled="currentPoi == route.path_pois[0]"
              @click="previousPoi"
            >
              <v-icon color="primary " size="35">mdi-skip-previous</v-icon>
            </v-btn>

            <div
              class="d-flex align-center justify-center px-2"
              ref="startPageHeight"
            >
              <!-- <v-avatar size="60" class="mr-4">
                <img src="@/assets/avatar.jpg" alt="John" />
              </v-avatar> -->

              <span
                class="body-1 primary--text text--darken-2"
                @click="openDialog(currentPoi.poi, 'poi_dialog')"
                >{{ currentPoi.poi.name }}
              </span>
            </div>

            <v-btn
              icon
              class="ml-auto"
              @click="nextPoi"
              :disabled="
                currentPoi == route.path_pois[route.path_pois.length - 1]
              "
            >
              <v-icon color="primary " size="35">mdi-skip-next</v-icon>
            </v-btn>
          </div>

          <v-divider class="mb-4"></v-divider>

          <span class="body-2 mb-4 description" v-html="route.description">
          </span>

          <div class="d-flex flex-column align-self-start mb-16"></div>
        </div>
      </div>
    </div>

    <!-- Route details -->
    <!-- Route details -->
    <!-- Route details -->
    <!-- Route details -->

    <!-- Info -->
    <PoiDialog class="dialog" :ppoi="dialog.poi" v-if="dialog.open && dialog.type == 'poi_dialog'" @close="closeDialog" />

    <v-dialog
      v-model="dialog.open"
      v-if="dialog.type == 'start_route_dialog'"
      max-width="100%"
    >
      <StartRouteDialog
        @startRoute="startRoute"
        v-if="dialog.open && dialog.type == 'start_route_dialog'"
        @close="closeDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapMutations, mapState } from "vuex";
import { decodeEntities } from "../../../utils/decodeEntities";

import PoiDialog from "../../../components/PoiDialog.vue";

import L from "leaflet";
import { LMap, LTileLayer, LMarker, LPolyline, LControl } from "vue2-leaflet";
import Leaflet,{ Icon } from "leaflet";
import CustomMarker from "vue-leaflet-custom-marker";

import { LocalNotifications } from "@capacitor/local-notifications";

import BackgroundGeolocation from "@transistorsoft/capacitor-background-geolocation";
import { Haptics } from "@capacitor/haptics";
import { TextToSpeech } from "@capacitor-community/text-to-speech";
import { Network } from "@capacitor/network";
import StartRouteDialog from "../../../components/StartRouteDialog.vue";
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  components: {
    PoiDialog,
    LMap,
    LTileLayer,
    LMarker,
    LPolyline,
    LControl,
    CustomMarker,
    StartRouteDialog,
  },

  mounted() {
    this.$nextTick(() => {
      this.map = this.$refs.map?.mapObject;
      // setTimeout(() => {
      //   this.$refs?.startPageHeight.scrollIntoView();
      // }, 2000);
    });
  },

  async created() {
    try {
      this.status = await Network.getStatus();

      this.loader = true;
      let route = null;
      if (!this.status.connected && this.$route.path.includes("saved-routes")) {
        route = await axios.get(`/savedRoute.json`);
      } else {
        route = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/paths/${this.$route.params.route_id}`
        );
      }

      this.route = route.data.path;

      this.route.polyline = JSON.parse(this.route.polyline);
      this.setCurrentRoute(this.route);

      // console.log(this.route.path_pois);
      this.currentPoi = this.route.path_pois[0];

      this.loader = false;
    } catch (e) {
      console.log(e);
    }
  },

  async destroyed() {
    BackgroundGeolocation.removeGeofences().then((success) => {
      console.log("[removeGeofences] all geofences have been destroyed");
    });
    await TextToSpeech.stop();
  },

  data() {
    return {
      map: null,
      route: null,
      currentPoi: null,
      loader: true,
      playing: false,
      start: false,
      locationLoader: false,
      status: false,
      icon: L.icon({
        iconUrl: "/circle.svg",
        iconSize: [32, 37],
        iconAnchor: [16, 37],
      }),

      marker: {
        lat: 0,
        lng: 0,
      },

      dialog: {
        open: false,
        poi: null,
        type: null,
      },

      options: {
        duration: 300,
        offset: 10,
        easing: "linear",
      },

      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 10,

      bounds: Leaflet.latLngBounds(Leaflet.latLng(-90, -200), Leaflet.latLng(90, 200)),
    };
  },

  computed: {
    ...mapState({
      currentArea: (state) => state.areas.currentArea,
      location: (state) => state.base.location,
      sound: (state) => state.base.sound,
    }),
    hasLocation() {
      return !!location;
    },
  },

  watch: {
    async sound(newValue, oldValue) {
      if (newValue == 0) await TextToSpeech.stop();
    },
  },

  methods: {
    ...mapMutations(["setCurrentRoute"]),
    ...mapActions(["toggleSound"]),

    async getLocation() {
      try {
        this.locationLoader = true;
        await this.$store.dispatch("getLocation");
        this.locationLoader = false;

        this.setView(this.location);
      } catch (e) {
        console.log(e);
      }
    },

    async startRoute(googleMaps) {
      if (googleMaps) {
        window.open(
          `https://www.google.com/maps/dir/?api=1&origin=${this.location.latitude}, ${this.location.longitude}&destination=${this.route.path_pois[0].poi.latitude}, ${this.route.path_pois[0].poi.longitude}&travelmode=driving`
        );
      }
      for (let i = 0; i < this.route.path_pois.length; i++) {
        BackgroundGeolocation.addGeofence({
          identifier: this.route.path_pois[i].poi.name,
          radius: 200,
          latitude: parseFloat(this.route.path_pois[i].poi.latitude),
          longitude: parseFloat(this.route.path_pois[i].poi.longitude),
          notifyOnEntry: true,
          notifyOnExit: true,
        })
          .then(async (success) => {})
          .catch((error) => {
            console.log("[addGeofence] FAILURE: ", error);
          });
      }

      try {
        await BackgroundGeolocation.onGeofence(async (geofenceEvent) => {
          if (geofenceEvent.action != "ENTER") return;

          const poi = this.route.path_pois.find(
            (el) => el.poi.name == geofenceEvent.identifier
          );

          if (poi == null) return;

          await Haptics.vibrate();
          await LocalNotifications.schedule({
            notifications: [
              {
                title: `${poi.poi.name}`,
                body: `${decodeEntities(poi.poi.description)}`,
                id: 1,
                schedule: { at: new Date(Date.now() + 100) },
                attachments: null,
                actionTypeId: "",
                extra: null,
              },
            ],
          });
          this.openDialog(poi.poi, "poi_dialog");
        });
      } catch (e) {
        console.log(e);
      }

      this.start = true;
      this.currentPoi = this.route.path_pois[0];
      this.setView(this.currentPoi.poi);
    },

    previousPoi() {
      const currentIndex = this.route.path_pois.indexOf(this.currentPoi, 0);
      if (currentIndex - 1 >= 0) {
        this.currentPoi = this.route.path_pois[currentIndex - 1];
        this.setView(this.currentPoi.poi);
      }
    },

    nextPoi() {
      const currentIndex = this.route.path_pois.indexOf(this.currentPoi, 0);
      if (currentIndex + 1 < this.route.path_pois.length) {
        this.currentPoi = this.route.path_pois[currentIndex + 1];
        this.setView(this.currentPoi.poi);
      }
    },

    setView(coords) {
      console.log(coords);
      this.$refs.map.mapObject.setView(
        [coords.latitude, coords.longitude],
        16,
        {
          animate: true,
          duration: 0.6,
        }
      );
    },

    async play() {
      try {
        if (this.sound == "0") await this.toggleSound(1);
        this.playing = true;
        const decodedText = decodeEntities(this.route.description);
        await TextToSpeech.speak({
          text: `${decodedText}`,
          lang: "el",
          rate: 0.9,
          pitch: 1.0,
          volume: this.sound,
          category: "ambient",
        });
        this.playing = false;
      } catch (e) {
        console.log(e);
      }
    },

    async stop() {
      try {
        await TextToSpeech.stop();
        this.playing = false;
      } catch (e) {
        console.log(e);
      }
    },

    openDialog(poi, type) {
      this.dialog = {
        open: true,
        poi,
        type,
      };
    },

    closeDialog() {
      this.dialog = {
        open: false,
        poi: null,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.dialog , .map{
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 0
}

.return-button {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  margin-left: 10px !important;
  margin-top: 10px !important;
}

.chevron-button {
  border-top-right-radius: 10px !important;
}

.description {
  p {
    width: 100px;
    word-wrap: break-word;
  }
}

.content {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.content-title {
  position: fixed;
  top: -10px;
  // left: 50%;
  // transform: translate(-50%, -50%);
  color: #fed136;
}

.page-body {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.view-more {
  width: 100% !important;
}

.pulse-button-start {
  -webkit-animation: pulse 1.5s infinite;
  box-shadow: 0 0 0 0 var(--v-secondary-base);
}

.leaflet-marker-icon .pulse-button-start:hover {
  -webkit-animation: none;
}

@-webkit-keyframes pulse {
  0% {
  }
  70% {
    box-shadow: 0 0 0 15px rgba(90, 153, 212, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(90, 153, 212, 0);
  }
}

.pulse-button {
  -webkit-animation: pulse-location 1.5s infinite;
  box-shadow: 0 0 0 8px rgba(#5a99d4, 0.5);
  border: 0.5px solid white;
}

.pulse-button:hover {
  -webkit-animation: none;
}

@-webkit-keyframes pulse-location {
  0% {
  }
  70% {
    // box-shadow: 0 0 0 8px rgba(90, 153, 212, 0);
  }
  100% {
    // box-shadow: 0 0 0 4px rgba(90, 153, 212, 0);
  }
}
</style>