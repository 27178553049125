<template>
  <v-btn
    icon
    :class="playDescription ? '' : 'pulse-button'"
    @click="togglePlay"
  >
    <v-icon v-if="!playing" color="white" class="primary rounded-circle" size="20">
      mdi-play
    </v-icon>
    <v-icon v-else
      color="white"
      class="primary rounded-circle"
      size="20"
    >
      mdi-stop
    </v-icon>
  </v-btn>
</template>

<script>
import { TextToSpeech } from "@capacitor-community/text-to-speech";
import { decodeEntities } from "../utils/decodeEntities";
import { mapActions,mapState } from "vuex";

export default {
  async destroyed() {
    try {
      await TextToSpeech.stop();
    } catch (e) {
      console.log(e);
    }
  },
  data(){
    return {
      playDescription: false,
      playing: false,
    }
  },
  computed:{
    ...mapState({
      sound: (state) => state.base.sound,
    }),
  },
  props:{
    description:{
      type: String,
      required: true,
    }
  },
  methods: {
    ...mapActions(["toggleSound"]),

    togglePlay(){
      if(this.playing){
        this.stop();
      }  
      else{
        this.play();
      }
    },

    async play() {
      try {
        if (this.sound == "0") await this.toggleSound("1");
        this.playDescirption = true;
        this.playing = true;
        const decodedText = decodeEntities(this.description);
        await TextToSpeech.speak({
          text: `${decodedText}`,
          lang: "el",
          rate: 0.9,
          pitch: 1.0,
          volume: this.sound,
          category: "ambient",
        });
        this.playing = false;
      } catch (e) {
        console.log(e);
      }
    },

    async stop() {
      try {
        await TextToSpeech.stop();
        this.playing = false;
      } catch (e) {
        console.log(e);
      }
    },
  },
}
</script>

<style scoped lang="scss">
.pulse-button {
  -webkit-animation: pulse 1.5s infinite;
  box-shadow: 0 0 0 0 rgba(#5a99d4, 0.5);
}

.pulse-button:hover {
  -webkit-animation: none;
}

@-webkit-keyframes pulse {
  0% {
    // transform: scale(1);
  }
  70% {
    // transform: scale(1.05);
    box-shadow: 0 0 0 5px rgba(90, 153, 212, 0);
  }
  100% {
    // transform: scale(1);
    box-shadow: 0 0 0 0 rgba(90, 153, 212, 0);
  }
}
</style>