<template>
  <div class="d-flex flex-column white" style="height: 100%" v-if="!loader">
    <!-- Return button -->
    <div
      class="secondary rounded-pill d-flex align-center return-button-fixed elevation-5"
      @click="
        $route.path.split('/')[4] == 'map'
          ? $router.push(`/locations/area/${currentArea.id}`)
          : $router.push(`/locations/area/${currentArea.id}/pois/results`)
      "
    >
      <v-btn text>
        <v-icon color="white">mdi-arrow-left-thin</v-icon>

        <span
          class="body-2 white--text pr-1"
        >
          {{
            $route.path.split("/")[4] == "map"
              ? currentArea.name
              : $t("pois.title")
          }}
        </span>
      </v-btn>
    </div>

    <!-- Map Button -->
    <div
      class="secondary rounded-pill d-flex align-center map-button mt-2 mr-2 elevation-5"
      @click="$emit('close')"
    >
      <v-btn text>
        <v-icon color="white">mdi-map-outline</v-icon>

        <span
        class="body-2 white--text pr-1"
        >
          {{$t("general.map")}}
        </span>
      </v-btn>
    </div>
  

    <v-card class="elevation-0">
      <v-carousel
        v-model="model"
        hide-delimiters
        height="250px"
        :continuous="false"
      >
        <v-carousel-item
          v-for="(image, i) in poi.images"
          :key="image + i"
          :src="image"
        >
        </v-carousel-item>
      </v-carousel>

      <div class="d-flex flex-column px-4 py-2">
        <!-- Tabs -->
        <div class="d-flex flex-row justify-center" style="column-gap: 8px;">
          <v-btn-toggle
            rounded
            class="secondary mb-2"
            mandatory
            v-model="toggle"
            dense
          >
            <v-btn text @click="tab='information'" class="pa-4">
              <v-icon color="white">mdi-text-box-multiple</v-icon>

              <span
              class="body-2 white--text"
              >
                {{$t("general.information")}}
              </span>
            </v-btn>

            <v-btn text @click="tab='directions'" outlined class="pa-4">
              <v-icon color="white">mdi-directions</v-icon>

              <span
              class="body-2 white--text"
              >
                {{$t("general.directions")}}
              </span>
            </v-btn>
          </v-btn-toggle>
        </div>

        <v-divider></v-divider>

        <div v-if="tab=='information'">
          <div class="d-flex align-center mb-2 mt-2">
            <span
              class="
                primary--text
                text--darken-2
                font-weight-regular
                text-h6
                mr-auto
              "
            >
              {{ poi.name }}
            </span>

            <!-- Play/Stop button -->
            <VoiceDescriptionButton :description="poi.description"></VoiceDescriptionButton>
          </div>

          <v-divider class="mb-4"></v-divider>
          <div>
            <span class="mb-16" :class="'body-2'" v-html="poi.description"> </span>
          </div>
        </div>

        <div v-if="tab=='directions'" class="mt-4 d-flex flex-column">
          <div class="mb-8">
            <span :class="'body-2'" v-html="poi.directions"></span>
          </div>

          <v-btn class="align-self-center" rounded outlined color="secondary"><v-icon>mdi-google-maps</v-icon> {{ $t("general.open_maps") }}  <v-icon class="ml-3">mdi-open-in-new</v-icon></v-btn>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import VoiceDescriptionButton from "./VoiceDescriptionButton.vue";
import { mapState } from "vuex";
import axios from "axios";

export default {
  components:{
    VoiceDescriptionButton
  },
  props: {
    ppoi: Object,
  },

  async created() {
    // try {
    //   const res = await axios.get(
    //     `${process.env.VUE_APP_BASE_URL}/pois/${this.ppoi.id}`
    //   );
    //   this.poi = res.data.poi;
    //   console.log(this.poi);

    //   if (this.sound == "1") await this.play();
    // } catch (e) {
    //   console.log(e);
    // }
    this.poi=this.ppoi;
    this.loader=false;
  },

  data() {
    return {
      model: 0,
      poi: null,
      tab: "information",
      toggle:[1],
      loader:true
    };
  },
  computed:{
    ...mapState({
      currentArea: (state) => state.areas.currentArea,
    })
  }
};
</script>

<style scoped lang="scss">
.inactive{
  opacity: 0.7;
}

.active{
  pointer-events: none;
}

.map-button {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1;
  margin-right: 10px !important;
  margin-top: 10px + 75px !important;
}

.return-button{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  margin-left: 10px !important;
  margin-top: 10px !important;
}

.pulse-button {
  -webkit-animation: pulse 1.5s infinite;
  box-shadow: 0 0 0 0 rgba(#5a99d4, 0.5);
}

.pulse-button:hover {
  -webkit-animation: none;
}

@-webkit-keyframes pulse {
  0% {
    // transform: scale(1);
  }
  70% {
    // transform: scale(1.05);
    box-shadow: 0 0 0 5px rgba(90, 153, 212, 0);
  }
  100% {
    // transform: scale(1);
    box-shadow: 0 0 0 0 rgba(90, 153, 212, 0);
  }
}
</style>