<template>
<div class="component-wrapper absolute-content">
  <v-btn text @click="$router.back()" class="return-button-sticky white elevation-5"> <v-icon>mdi-arrow-left</v-icon> {{ $t('health.title') }}</v-btn>

  <div v-if="entry" class="pa-3 d-flex flex-column">
    <h2>{{entry.name}}</h2>
    <div class="d-flex flex-row text-h6"> 
      <v-icon>{{ category.icon }}</v-icon>
      {{ category.name }}
    </div>
    <v-divider></v-divider>
    <!-- Address -->
    <div class="d-flex flex-row justify-space-between my-2 align-center flex-wrap">
      <div class="ml-2"> <v-icon>mdi-map-marker</v-icon> <span class="font-weight-bold mr-1">{{$t("general.address")}}:</span> {{ entry.address }}</div>
      <v-btn outlined class="primary--text"><v-icon>mdi-google-maps</v-icon> <span>{{$t("general.open_maps")}}</span> </v-btn>
    </div>
    <v-divider></v-divider>
    <!-- Contact info -->
    <h4 class="mt-2 ml-1">{{$t("general.contact_info")}} :</h4>
    <!-- Phone -->
    <div class="d-flex flex-row justify-space-between my-2 align-center flex-wrap" v-if="entry.phone">
      <div class="ml-2"> <v-icon>mdi-phone</v-icon> <span class="font-weight-bold mr-1">{{$t("general.phone")}}:</span> {{ entry.phone }}</div>
      <v-btn outlined class="primary--text"><v-icon>mdi-phone-forward</v-icon> <span>{{$t("general.call")}}</span> </v-btn>
    </div>
    <!-- Email -->
    <div class="d-flex flex-row justify-space-between my-2 align-center flex-wrap" v-if="entry.email">
      <div class="ml-2"> <v-icon>mdi-at</v-icon> <span class="font-weight-bold mr-1">Email:</span> {{ entry.email }}</div>
      <v-btn outlined class="primary--text"><v-icon>mdi-email-arrow-right-outline</v-icon> <span>{{$t("general.send_email")}}</span> </v-btn>
    </div>
    <!-- Fax -->
    <div class="d-flex flex-row justify-space-between my-2 align-center flex-wrap" v-if="entry.fax">
      <div class="ml-2"> <v-icon>mdi-fax</v-icon> <span class="font-weight-bold mr-1">FAX:</span> {{ entry.fax }}</div>
    </div>
    <!-- Website -->
    <a class="ml-2 mb-2 secondary--text" v-if="entry.website">{{entry.website}} <v-icon>mdi-open-in-new</v-icon></a>
    <v-divider></v-divider>
    <!-- Description -->
    <span class="body-2 my-2" v-html="entry.description"></span>
  </div>
</div>
</template>

<script>
export default{
  async created() {
    try {
      if(this.$store.state.base.mobileLang){
        const entries = require(`../../../examples/health_${this.$store.state.base.mobileLang.code}.json`);

        this.entry = entries.filter(entry => {
          return entry.id === parseInt(this.$route.params.entry_id);
        })[0];

        var categories = require(`../../../examples/health_categories_${this.$store.state.base.mobileLang.code}.json`);
        this.category = categories.filter(category => {
          return category.id === this.entry.category_id;
        })[0]
      }
    } catch (e) {
      console.log(e);
    }
  },
  data() {
    return {
      entry: null,
      category: null
    }
  },
}
</script>