<template>
  <div class="component-wrapper">
    <div
      v-if="loader"
      class="d-flex flex-column align-center justify-center flex-grow-1"
      style="height: 500px"
    >
      <v-progress-circular indeterminate color="primary" size="60">
      </v-progress-circular>
    </div>

    <div v-else class="d-flex flex-column">
      <router-view :key="$route.fullPath"></router-view>

      <v-bottom-navigation app color="white" class="primary darken-1" grow>
        <v-btn
          v-for="(item, i) in bottomNavigation"
          :key="i"
          plain
          :to="item.path"
          :exact="item.exact"
          class="bottomnav"
        >
          <span class="caption">{{ item.label }}</span>
          <v-icon> {{ item.icon }}</v-icon>
        </v-btn>
        <!-- <v-btn plain @click="toggle">
          <span class="white--text caption">
            {{ $t("locations.voice_button") }}
          </span>
          <v-icon color="white">
            {{ sound == 1.0 ? "mdi-volume-high" : "mdi-volume-mute" }}</v-icon
          >
        </v-btn> -->
      </v-bottom-navigation>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  async created() {
    // try {
    //   const res = await axios.get(
    //     `${process.env.VUE_APP_BASE_URL}/areas/${this.$route.params.area_id}`
    //   );
    //   this.area = res.data.area;

    //   this.setCurrentArea(this.area);

    //   this.loader = false;
    // } catch (e) {
    //   console.log(e);
    // }
    this.setArea(parseInt(this.$route.params.area_id));
    this.loader = false;
  },

  data() {
    return {
      route: null,
      loader: true,
    };
  },

  computed: {
    ...mapState({
      currentRoute: (state) => state.routes.currentRoute,
      currentPoi: (state) => state.pois.currentPoi,
      sound: (state) => state.base.sound,
      area: (state) => state.areas.currentArea, 
    }),

    bottomNavigation() {
      return [
        {
          label: this.area.name,
          path: `/locations/area/${this.$route.params.area_id}/`,
          icon: "mdi-text-box",
          exact:true
        },
        {
          label: this.$t("locations.routes_button"),
          path: `/locations/area/${this.$route.params.area_id}/routes`,
          icon: "mdi-map-marker-path",
        },
        {
          label: this.$t("locations.pois_button"),
          path: `/locations/area/${this.$route.params.area_id}/pois`,
          icon: "mdi-map-marker-multiple",
        },

        {
          label: this.$t("locations.map_button"),
          path: `/locations/area/${this.$route.params.area_id}/map`,
          icon: "mdi-map-outline",
        },
      ];
    },
  },

  methods: {
    ...mapActions(["toggleSound","getAreas","setArea"]),

    toggle() {
      if (this.sound == "1") {
        this.toggleSound("0");
      } else {
        this.toggleSound("1");
      }
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-btn--active.bottomnav{
  background-color: rgba(255, 255, 255, 0.8) !important;
  >.v-btn__content{
    opacity : 1 !important;
    .v-icon{
      color: black !important;
    }
    span{
      color: black !important;
    }
  }
}

::v-deep .v-btn.bottomnav:not(.v-btn--active) > .v-btn__content{
  opacity : 1 !important;
  .v-icon{
    color: white !important;
  };
  span{
    color: white !important;
  }
}
</style>