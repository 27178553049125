<template>
  <div class="d-flex flex-column align-center">
    <div class="image-container" @click="viewArea">
      <div class="image-card d-flex flex-column align-center">
        <span class="secondary--text font-weight-bold text-h6 mb-4">
          {{ this.area.name }}
        </span>

        <v-icon color="secondary" class="map-marker-icon"
          >mdi-map-marker</v-icon
        >
      </div>

      <v-img
        :aspect-ratio="16 / 9"
        width="100vw"
        :src="image"
        @error="onImageError"
      />
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  props: {
    area: Object,
  },

  data() {
    return {
      failed_image: false,
    };
  },

  computed: {
    image() {
      // return this.failed_image
      //   ? "https://iguide.mobics.gr/images//ilioupoli_opt_.jpg"
      //   : `https://iguide.mobics.gr/images//${
      //       this.area.image.file_name.split(".")[0]
      //     }_opt_.jpg`;
      return this.area.image
    },
  },

  methods: {
    ...mapMutations(["setCurrentArea"]),

    onImageError() {
      console.log("?");
      this.failed_image = true;
    },

    viewArea() {
      this.setCurrentArea(this.area);
      this.$router.push(`/locations/area/${this.area.id}`);
    },
  },
};
</script>

<style scoped lang="scss">
.image {
  cursor: pointer;

  &-container {
    position: relative;
  }

  &-card {
    position: absolute;
    top: 5%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    margin: auto;
    z-index: 1;
    background-color: white;
    width: 95%;

    &:after {
      content: "";
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid white;
      position: absolute;
      left: 50%;
      bottom: -10px;
      margin-left: -10px;
      z-index: 1;
    }
  }
}

.map-marker-icon {
  position: absolute;
  left: 50%;
  bottom: -10px;
  margin-left: -12px;
  z-index: 2;
}
</style>