<template>
  <div class="component-wrapper secondary d-flex flex-column categories">
    <!-- Header -->
    <div class="d-flex align-center">
      <h1 class="white--text font-weight-bold text-h5 px-2">
        {{ $t(`health.title`) }}
      </h1>
    </div>
    <span class="caption grey--text px-4 mb-2">
      {{ $t(`general.categories_label`)}}
    </span>

    <!-- Loader -->
    <div
      v-if="loader"
      class="d-flex flex-column align-center flex-grow-1"
      style="height: 500px"
    >
      <v-progress-circular indeterminate color="white" size="60">
      </v-progress-circular>
    </div>


    <!-- Categories Select -->
    <v-container v-else-if="!loader && categories.length>0">
      <v-row>
        <v-col
          v-for="(category, i) in categories"
          :key="i"
          cols="6"
          class="card d-flex flex-column"
          @click="onCategorySelect(category)"
        >
          <div class="card-2 d-flex flex-column align-center justify-center">
            <v-icon color="white" size="45"> {{ category.icon }}</v-icon>
            <span class="white--text body-1 text-center">
              {{ category.name }}
            </span>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <span v-else-if="!loader && categories.length==0" class="white--text px-2">{{$t(`health.not_found_categories`)}}</span>
  </div>
</template>

<script>
import axios from "axios";

export default {
  async created() {
    try {
      if(this.$store.state.base.mobileLang){
        this.categories=require(`../../../examples/health_categories_${this.$store.state.base.mobileLang.code}.json`);
      }
      this.loader = false;
    } catch (e) {
      this.loader = false;
      console.log(e);
    }
  },

  data() {
    return {
      loader: true,
      categories: [],
    };
  },

  methods: {
    onCategorySelect(category) {
      this.$router.push({name:'health_results',query:{category:category.id}});
    },
  },
};
</script>

<style scoped lang="scss">
.card {
  height: 150px !important;
  width: 100px !important;
}

.card-2 {
  height: 100% !important;
  width: 100% !important;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 8px;
}

.card-2:hover {
  transition: 0.3s ease;
  background: rgba(255, 255, 255, 0.3);
}

.categories {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 0;
  overflow-y: auto;
}
</style>