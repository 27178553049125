<template>
<div>
  <v-btn text @click="openHelp" style="height: auto;" :class="{disabled: disabled}" :disabled="disabled">
    <div class="d-flex flex-column">
      <v-icon color="white">
        mdi-help
      </v-icon>
      <span class="white--text">{{$t("appbar.help")}}</span>
    </div>
  </v-btn>

  <v-dialog v-model="dialogOpen" @hook:mounted="cardMount">
    <v-card style="height:90vh ;overflow-y: auto" id="scrollTarget">
      <div style="position: sticky; top:0; z-index: 1; width: 100%;" :class="(scrollPosition===0) ? '' : 'toolbar'">
        <v-toolbar flat>
          <v-toolbar-title>
            {{$t("help.title")}}
          </v-toolbar-title>

          <v-btn icon @click="closeHelp"> <v-icon>mdi-close</v-icon> </v-btn>
        </v-toolbar>

        <v-divider></v-divider>
      </div>
      
      <v-card-text v-scroll:#scrollTarget="onScroll" class="py-3">
        <div v-for="content in help_content" :key="content.id"> 
          <h1 style="line-height: 1.1em;">{{content.title}}</h1>
          <v-divider class="mt-3"></v-divider>
          <div v-for="section in content.sections" :key="section.order" class="px-2 mt-2">
            <h2>
              {{section.title}}
            </h2>
            
            <p class="px-2 mt-1">
              {{ section.text }}
            </p>

            <v-img 
              v-for="image in section.images" 
              :src="require(`../../examples/Images/${image.image}`)" 
              :key="image.order" 
              width="300px" 
              contain
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              class="ma-2 white--text align-end pa-3"
            >
              <h3>{{ image.caption }}</h3>
            </v-img>
          </div>
        </div>
      </v-card-text>

      <div style="position: sticky; bottom:0; z-index: 1; width: 100%; height: 10px;" 
        :class="scrollHeight && (scrollPosition < scrollHeight) ? 'botbar' : ''" 
        class="white"
      >
      </div>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
export default{
  created(){
    this.fetchHelp();
  },
  data() {
    return {
      dialogOpen: false,
      help_content: [],
      scrollPosition: 0,
      scrollHeight: null,
      disabled:false,
    };
  },
  methods: {
    openHelp(){
      this.dialogOpen = true;
    },
    closeHelp(){
      this.dialogOpen = false;
    },
    fetchHelp(){
      if(this.$store.state.base.mobileLang){
        const help_content=require(`../../examples/help_${this.$store.state.base.mobileLang.code}.json`)
        this.help_content = help_content.filter(help => {
          return help.url===this.$route.path;
        })

        if (this.help_content.length===0){
          this.help_content = help_content.filter(help => {
            return help.url===null;
          })
          this.disabled = true;
        }
        else this.disabled = false;
      }
    },
    onScroll(e){
      this.scrollHeight = e.target.scrollHeight - e.target.clientHeight;
      this.scrollPosition = e.target.scrollTop;
    },
    cardMount(){
      if(this.dialogOpen && this.$refs['scrollTarget'])
        this.scrollHeight = this.$refs['scrollTarget'].scrollHeight - this.$refs['scrollTarget'].clientHeight;
    }
  },
  watch:{
    '$route.path'(){
      this.fetchHelp();
    },
    dialogOpen(visible) {
      if(visible){
        this.scrollPosition = 0;
      }
    },
  }
}
</script>

<style scoped lang="scss">
.toolbar{
  box-shadow: 0 .125rem .3125rem rgba(0,0,0,.26);
}

.botbar{
  box-shadow: 0 -.125rem .3125rem rgba(0,0,0,.26);
}

.disabled{
  opacity: 0.4;
}

::v-deep .v-btn--disabled.disabled{
  >.v-btn__content{
    .v-icon{
      color: white !important;
    }
  }
}
</style>