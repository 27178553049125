<template>
  <div class="component-wrapper d-flex flex-column">
    <v-btn icon @click="$router.push('/info')" class="ma-2">
      <v-icon color="primary">mdi-arrow-left</v-icon>
    </v-btn>

    <div
      class="d-flex flex-column align-center pa-4 white"
    >
      <v-form @submit.prevent="submit">
        <h1 class="secondary--text font-weight-bold text-h5">Contact us</h1>
        <span class="caption grey--text text--darken-3">Send us a message</span>
        <v-text-field
          v-model="email"
          type="email"
          solo
          :error-messages="emailErrors"
          class="mb-2 mt-4"
          color="secondary"
          style="width: 272px"
        >
          <template v-slot:label>
            <div class="body-2">Email</div>
          </template>
        </v-text-field>
        <v-text-field
          v-model="phone"
          label="Phone"
          type="number"
          :error-messages="phoneErrors"
          class="mb-2"
          color="secondary"
          solo
          style="width: 272px"
        >
          <template v-slot:label>
            <div class="body-2">Phone</div>
          </template>
        </v-text-field>
        <v-textarea
          solo
          size="10"
          label="Message"
          v-model="message"
          :error-messages="messageErrors"
          color="secondary"
          class="mb-2"
          style="width: 272px"
        >
          <template v-slot:label>
            <div class="body-2">Message</div>
          </template>
        </v-textarea>
        <v-btn color="secondary body-2" width="100%" type="submit"
          >SEND MESSAGE</v-btn
        >
      </v-form>
    </div>

    <div
      class="
        secondary
        white--text
        body-2
        font-weight-bold
        d-flex
        align-center
        justify-center
        pa-4
      "
    >
      <span> Περιφερειακή Ένωση Δήμων Ιονίων Νήσων (ΠΕΔ-ΙΝ) </span>
    </div>

    <div class="d-flex justify-center secondary flex-grow-1 pa-4">
      <div class="body-2 mr-5 d-flex">
        <div>
          <v-icon size="8" color="white" class="mr-1">mdi-circle</v-icon>
        </div>
        <div class="d-flex flex-column">
          <span class="white--text">Λεωφόρος Αλεξάνδρας 13</span>
          <span class="white--text"> Τ.Κ. 49100</span>
          <span class="white--text"> Κέρκυρα</span>
        </div>
      </div>
      <div class="body-2 d-flex flex-column">
        <span class="white--text">
          <v-icon size="8" color="white">mdi-circle</v-icon> 26610 49008
        </span>
        <span class="white--text">
          <v-icon size="8" color="white">mdi-circle</v-icon> 26610 27823
        </span>
        <span class="white--text">
          <v-icon size="8" color="white">mdi-circle</v-icon> 26610 25657
        </span>
        <span class="white--text">
          <v-icon size="8" color="white">mdi-circle</v-icon> 26610 81823
        </span>
        <span class="white--text">
          <v-icon size="8" color="white">mdi-circle</v-icon> info@ped-in.gr
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  required,
  email,
  minLength,
  maxLength,
  numeric,
} from "vuelidate/lib/validators";

export default {
  data() {
    return {
      message: null,
      email: null,
      phone: null,
    };
  },

  validations: {
    message: {
      required,
    },

    email: {
      required,
      email,
    },

    phone: {
      required,
      numeric,
      minLength: minLength(6),
      maxLength: maxLength(12),
    },
  },

  computed: {
    messageErrors() {
      const errors = [];
      if (!this.$v.message.$dirty) return errors;
      if (!this.$v.message.required) errors.push("Required field");
      return errors;
    },

    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      if (!this.$v.email.required) errors.push("Required field");
      if (!this.$v.email.email)
        errors.push("Please enter a valid email address");
      return errors;
    },

    phoneErrors() {
      const errors = [];
      if (!this.$v.phone.$dirty) return errors;
      if (!this.$v.phone.required) errors.push("Required field");
      if (!this.$v.phone.numeric) errors.push("Only digits allowed");
      if (!this.$v.phone.minLength)
        errors.push("The number should be between 6-12 digits");
      if (!this.$v.phone.maxLength)
        errors.push("The number should be between 6-12 digits");

      return errors;
    },
  },

  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.$store.commit("toggleSnackbar", {
        open: true,
        text: "Your message has been sent successfully.",
        color: "success",
      });
    },
  },
};
</script>

<style scoped lang="scss">
</style>