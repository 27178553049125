<template>
  <div class="component-wrapper d-flex flex-column align-center">
    <v-btn
      @click="$router.push('/login')"
      icon
      color="secondary"
      class="align-self-start"
    >
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>

    <v-form @submit.prevent="submit" class="d-flex flex-column mt-7">
      <h1 class="secondary--text font-weight-bold text-h5">
        {{ $t("register.title") }}
      </h1>
      <span class="caption grey--text text--darken-3 mb-4">
        {{ $t("register.subtitle") }}
      </span>
      <v-text-field
        v-model="name"
        solo
        type="text"
        color="primary"
        :error-messages="nameErrors"
      >
        <template v-slot:label>
          <div class="body-2">
            {{ $t("register.fullname_label") }}
          </div>
        </template>
      </v-text-field>

      <v-text-field
        v-model="email"
        solo
        type="email"
        color="primary"
        :error-messages="emailErrors"
      >
        <template v-slot:label>
          <div class="body-2">
            {{ $t("register.email_label") }}
          </div>
        </template>
      </v-text-field>

      <v-text-field
        v-model="password"
        solo
        type="password"
        color="primary"
        :error-messages="passwordErrors"
      >
        <template v-slot:label>
          <div class="body-2">
            {{ $t("register.password_label") }}
          </div>
        </template>
      </v-text-field>

      <v-text-field
        v-model="confirmPassword"
        solo
        type="password"
        color="primary"
        :error-messages="confirmPasswordErrors"
      >
        <template v-slot:label>
          <div class="body-2">
            {{ $t("register.password_confirm_label") }}
          </div>
        </template>
      </v-text-field>

      <v-btn color="secondary body-2" type="submit">
        {{ $t("register.create_button") }}
      </v-btn>
      <div class="d-flex align-center mt-1 align-self-center">
        <span class="caption">
          {{ $t("register.already_have_account_text") }}
        </span>
        <v-btn
          text
          link
          class="caption pa-0 ml-1"
          color="primary"
          style="text-transform: none !important"
          @click="$router.push('/login')"
        >
          {{ $t("register.login_button") }}
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import axios from "axios";
import {
  required,
  email,
  minLength,
  maxLength,
  sameAs,
} from "vuelidate/lib/validators";
export default {
  data() {
    return {
      name: null,
      email: null,
      password: null,
      confirmPassword: null,
    };
  },

  validations: {
    name: {
      required,
    },

    email: {
      required,
      email,
    },

    password: {
      required,
      minLength: minLength(4),
      maxLength: maxLength(4),
    },

    confirmPassword: {
      required,
      sameAs: sameAs("password"),
    },
  },

  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      if (!this.$v.name.required) errors.push(this.$t("validation.required"));

      return errors;
    },

    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      if (!this.$v.email.required) errors.push(this.$t("validation.required"));

      if (!this.$v.email.email) errors.push(this.$t("validation.email"));
      return errors;
    },

    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      if (!this.$v.password.required)
        errors.push(this.$t("validation.required"));
      if (!this.$v.password.minLength)
        errors.push(this.$t("validation.password"));
      if (!this.$v.password.maxLength)
        errors.push(this.$t("validation.password"));
      return errors;
    },

    confirmPasswordErrors() {
      const errors = [];
      if (!this.$v.confirmPassword.$dirty) return errors;
      if (!this.$v.confirmPassword.required)
        errors.push(this.$t("validation.required"));

      if (!this.$v.confirmPassword.sameAs)
        errors.push(this.$t("validation.confirm_password"));

      return errors;
    },
  },

  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      axios.post(`${process.env.VUE_APP_BASE_URL}/user/create`, {
        email: this.email,
        password: this.password,
        name: this.name,
      });

      this.$store.commit("toggleSnackbar", {
        open: true,
        text: "Your account has been successfully created.",
        color: "success",
      });

      this.$router.push("/login");
    },
  },
};
</script>

<style scoped lang="scss">
.v-btn:not(.v-btn--round).v-size--default {
  min-width: 0 !important;
}
</style>