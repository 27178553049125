<template>
  <div v-if="!loader" class="component-wrapper absolute-content">
    <!-- Return Button -->
    <v-btn text class="white--text secondary mt-2 ml-2 rounded-pill return-button-sticky elevation-5" @click="$router.push('/events')">
      <v-icon>mdi-arrow-left</v-icon> <span>{{$t("events.title")}}</span> 
    </v-btn>

    <!-- Info -->
    <div class="d-flex flex-column pa-3" style="row-gap: 10px;">
      <!-- Image/Title/Type -->
      <v-img
        aspect-ratio="16 / 9"
        height="250px"
        width="100%"
        :src="event.image"
        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
        class="white--text align-end align-self-center"
      >
        <div class="d-flex flex-column ma-2" style="margin-right: auto;">
          <div><v-icon color="white">{{ category.icon }}</v-icon> {{category.name}}</div>
          <v-divider class="white" style="width: 100% !important;"></v-divider>
        </div>
        <h2 class="ma-2">{{ event.name }}</h2>
      </v-img>

      <v-divider class="black"></v-divider>
      <!-- Date -->
      <h3>Friday April 14th - 17:00-19:00</h3>
      <v-divider class="black"></v-divider>
      <!-- Description -->
      <div>{{ event.description }}</div>

      <v-divider class="black"></v-divider>
      <!-- Area -->
      <div class="flex-row">
        <v-icon>mdi-map-marker</v-icon>{{$t('events.location')}}:
        <span class="font-weight-bold">{{area.name}}</span>, 
        {{ event.venue }}       
      </div>

      <v-divider class="black"></v-divider>
      <!-- Price -->
      <div style="white-space: nowrap;">
        <v-icon>mdi-cash-multiple</v-icon>
        {{$t('events.price')}}: {{ event.price_euro }} €
      </div>

      <v-divider class="black"></v-divider>
      <div v-if="event.website">
      {{$t('events.website')}}:  <a class="secondary--text">{{event.website}} <v-icon>mdi-open-in-new</v-icon></a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions,mapState} from 'vuex';

export default{
  created(){
    this.getEvent(parseInt(this.$route.params.event_id));
    this.loader = false;

    var categories = require(`../../../examples/categories_events_${this.$store.state.base.mobileLang.code}.json`);
    this.category = categories.filter(category => {
      return category.id === this.event.category_id;
    })[0]
    var areas = require(`../../../examples/locations_${this.$store.state.base.mobileLang.code}.json`);
    this.area = areas.filter(area => {
      return area.id === this.event.location_id;
    })[0]
  },
  data(){
    return {
      loader: true,
      category:null,
      area:null
    }
  },
  computed:{
    ...mapState({
      event: (state) => state.events.currentEvent,
    })
  },
  methods: {
    ...mapActions({getEvent:'events/getEvent'}),
  }
};
</script>