<template>
  <v-app class="my-application" :style="cssProps">
    <Appbar v-if="setUp" :globalScroll="scrollPosition"></Appbar>

    <v-main>
      <PageLoader v-if="loader"></PageLoader>
      <router-view :key="$route.matched[0].path"></router-view>

      <v-snackbar v-model="snackbar.open" :color="snackbar.color">
        {{ snackbar.text }}
        <template v-slot:action="{ attrs }">
          <v-btn dense text icon x-small v-bind="attrs" @click="closeSnackbar">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>


<script>
import PageLoader from "./components/PageLoader.vue";
import Appbar from "./components/Appbar.vue";
import { Device } from "@capacitor/device";
import { mapActions, mapMutations, mapState } from "vuex";

import BackgroundGeolocation from "@transistorsoft/capacitor-background-geolocation";
import { Preferences } from "@capacitor/preferences";

import "leaflet/dist/leaflet.css";

export default {
  components: {
    Appbar,
    PageLoader,
  },

  async created() {
    try {
      await this.getLocation();
      this.loader = false;
      const sound = await Preferences.get({ key: "sound" });
      this.toggleSound(sound.value);

      BackgroundGeolocation.onLocation(
        (location) => {
          console.log("[onLocation] success: ", location);
          this.getLocation(location);
        },
        (error) => {
          console.log("[onLocation] ERROR: ", error);
        }
      );

      this.toggleSnackbar({
        open: false,
        text: null,
        color: null,
      });

      // if (this.$store.state.base.mobileLang == null) {
      //   const code = await Device.getLanguageCode();
      //   this.setMobileLang(code.value.split("-")[0]);
      //   this.$i18n.locale = code.value.split("-")[0];
      // } else this.$i18n.locale = this.mobileLang.code;

      this.setMobileLang("en");
      this.$i18n.locale = "en";
      // App.addListener("backButton", this.$router.go(-1));

      window.addEventListener('scroll', this.onScroll);
    } catch (e) {
      console.log(e);
    }
  },

  mounted() {
    BackgroundGeolocation.ready({
      desiredAccuracy: BackgroundGeolocation.DESIRED_ACCURACY_HIGH,
      distanceFilter: 10,
      stopTimeout: 1,
      debug: true,
      logLevel: BackgroundGeolocation.LOG_LEVEL_VERBOSE,
      stopOnTerminate: false,
      startOnBoot: true,
      geofenceModeHighAccuracy: true,
      geofenceProximityRadius: 1000,
      locationAuthorizationRequest: "Always",
      locationAuthorizationAlert: {
        titleWhenNotEnabled: "Location-services not enabled",
        titleWhenOff: "Location-services OFF",
        instructions: "You must enable 'Always' in location-services.",
        cancelButton: "Cancel",
        settingsButton: "Settings",
      },
      backgroundPermissionRationale: {
        title: "Allow access to this device's location in the background?",
        message:
          "In order to allow background geolocation, please enable 'Allow all the time' permission",
        positiveAction: "Change to 'Allow all the time'",
      },

      notification: {
        title: "Ionian Islands",
        text: "Tracking is on",
        smallIcon: "mipmap/ic_launcher/ic_launcher",
        largeIcon: "mipmap/ic_launcher/ic_launcher",
      },
    }).then(async (state) => {
      await BackgroundGeolocation.start();
    });
  },

  unmounted () {
    window.removeEventListener('scroll', this.onScroll);
  },

  data() {
    return {
      batteryLevel: null,
      eventLocation: null,
      location: null,
      loader: true,
      scrollPosition: 0,
    };
  },

  computed: {
    ...mapState({
      mobileLang: (state) => state.base.mobileLang,
      setUp: (state) => state.base.setUp,
    }),

    snackbar: {
      get() {
        return this.$store.state.base.snackbar;
      },
      set(value) {
        this.toggleSnackbar(value);
      },
    },

    cssProps() {
      return {
        '--font-size': this.$store.state.base.ui.fontSize,
        '--icon-size': this.$store.state.base.ui.iconSize,
      }
    }
  },

  methods: {
    ...mapMutations(["setMobileLang", "toggleSnackbar", "toggleSound"]),
    ...mapActions(["getLocation"]),

    closeSnackbar() {
      this.toggleSnackbar({
        open: false,
        text: null,
        color: null,
      });
    },
    onScroll(_e){
      this.scrollPosition = window.scrollY;
    },
  },

  watch: {
    '$route.path'(){
      this.globalScroll = 0;
    },
  }
};
</script>


<style lang="scss">
//======VUETIFY OVERRIDES========

.v-main__wrap {
  display: flex;
}

.v-toolbar__content {
  padding: 10px !important;
  justify-content: space-between !important;
  flex-grow: 1;
}

// .v-text-field--outlined fieldset {
//   border-color: #e9ecef !important;
//   border-radius: 1px !important;

//   transition: border-color 0.15s ease-in-out !important;
// }
// .v-input--is-focused fieldset {
//   border: 1px solid #ccc !important;
// }

// .v-input--selection-controls {
//   margin-top: 0px !important;
//   padding: 0px !important;
// }

.v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
  min-height: 25px !important;
} //search input height

.v-icon.notranslate.mdi.mdi-circle-outline.theme--light {
  color: white !important;
} //checkbox color when unchecked

//======GLOBAL STYLES=======
.component-wrapper {
  flex-grow: 1;
  // padding-top: 10px;
}

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap");
$font-family: "Open Sans", sans-serif;
.my-application {
  *:not(.v-icon),
  *:not(.v-icon):before,
  *:not(.v-icon):after {
    font-family: $font-family !important;
  }

  //transition styles
  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.2s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}

// html, body {
//   margin: 0; 
//   height: 100%;
//   overflow: auto;
// }

html {
  font-size: var(--font-size) ;
}

.v-icon.v-icon {
  font-size: var(--icon-size);
}

.v-input--checkbox .v-label{
  font-size: var(--font-size);
}

.v-btn {
  text-transform: none !important;
}

.flex-1{
  flex:1;
}

.return-button-sticky{
  margin-left: 10px; 
  margin-top: 10px;
  position:sticky;
  top:10px;;
  left:0;
  z-index: 1;
}

.return-button-fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  margin-left: 10px !important;
  margin-top: 10px + 75px !important;
}

.absolute-content{
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 0;
  overflow-y: auto;
}
</style>
