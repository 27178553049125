import Area from '../views/Area/Area.vue'

import Review from '../views/Area/Review.vue'

import Routes from '../views/Area/Routes/Routes.vue'
import Route from '../views/Area/Routes/Route.vue'

// import Pois from '../views/Area/Pois/Pois.vue'
import Categories from '../views/Area/Categories.vue'
import Results from '../views/Area/Results.vue'
import placeholderRoute from '../views/Area/Routes/placeholderRoute.vue'

import PoiMap from '../views/Area/Map/Map.vue'

import Map from '../views/Area/Map/Map.vue'



export default [
    {
        path: '/locations/area/:area_id',
        component: Area,
        children: [
            {
                path: '/',
                component: Review,
            },

            {
                path: 'routes',
                component: Categories,
                props: {type: 'routes' }
            },

            {
                path: 'routes/results',
                component: Results,
                props: {type: 'routes' }
            },

            {
                path: 'routes/:route_id',
                component: placeholderRoute,
            },
            {
                path:'pois',
                component: Categories,
                props: {type: 'pois' }
            },
            {
                path: 'pois/results',
                component: Results,
                props: {type: 'pois' }
            },
            {
                path: 'pois/:poi_id/map',
                component: PoiMap,
            },

            {
                path: 'map',
                component: Map,
            },
        ]
    },
]