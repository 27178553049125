export default {
  namespaced: true,
  state: {
    currentPoi: null,
    pois: [],
    categories:[]
  },

  mutations: {
    setCurrentPoi(state, currentPoi) {
      state.currentPoi = currentPoi
    },
    setPois(state, pois) {
      state.pois = pois;
    },
    setCategories(state,categories){
      state.categories = categories;
    }
  },
  
  actions:{
    async getPois({commit, rootState}) {
      var pois=[];
      if(rootState.base.mobileLang){
        pois=require(`../../examples/pois_${rootState.base.mobileLang.code}.json`)
      }

      if(rootState.base.filters.area)
      {
        pois=pois.filter(poi => {
          return poi.area_id === rootState.base.filters.area;
        })
      }
      if(rootState.base.filters.category && rootState.base.filters.category.id){
        pois = pois.filter(poi => {
          return poi.category_id === rootState.base.filters.category.id;
        })
      }

      commit("setPois", pois);
    },

    async getCategories({commit,rootState}) {
      var categories=null;
      if(rootState.base.mobileLang){
        categories=require(`../../examples/categories_pois_${rootState.base.mobileLang.code}.json`);
      }
      commit("setCategories", categories);
    },

    getPoi({commit,rootState,dispatch},poi_id){
      commit("setFilters",{area:rootState.areas.currentArea.id,category:rootState.base.filters.category},{root:true});
      var pois=[];
      if(rootState.base.mobileLang){
        pois=require(`../../examples/pois_${rootState.base.mobileLang.code}.json`)
      }
      pois = pois.filter(poi => {
        return poi.id === poi_id
      })
      commit("setPois", pois);
    }
  }
}