import axios from "axios";
export default {
  state: {
    areas: null,
    area: null,
    currentArea: null,
  },

  mutations: {
    setAreas(state, areas) {
      state.areas = areas;
    },

    getArea(state, area) {
      state.area = area;
    },

    setCurrentArea(state, currentArea) {
      state.currentArea = currentArea;
    },
  },

  actions: {
    async getAreas({commit, rootState}) {
      // try {
      //   const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/areas`);
      //   const sortedAreas = res.data.areas.sort((a, b) => {
      //     return a.weight - b.weight;
      //   })
      //   console.log(sortedAreas)

      //   commit("setAreas", sortedAreas);
      // } catch (e) {
      //   throw e;
      // }
      var sortedAreas=null;
      if(rootState.base.mobileLang){
        sortedAreas=require(`../../examples/locations_${rootState.base.mobileLang.code}.json`)
      }
      commit("setAreas", sortedAreas);
    },
    setArea({dispatch,commit,state},area_id) {
      if(!state.areas) dispatch("getAreas");
      const area = state.areas.filter(area => {
        return area.id === area_id;
      })[0]
      commit("setCurrentArea",area);
    },
    getAreaPois({dispatch,commit,state,rootState}) {
      commit("setFilters",{area:state.currentArea.id,category:null});
      dispatch("pois/getPois");
    }
  },
};
