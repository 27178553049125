<template>
  <div class="d-flex flex-column align-center flex-grow-1">
    <!-- Return Button -->
    <div>
      <div
        class="
          secondary
          rounded-pill
          d-flex
          align-center
          return-button-fixed
          elevation-5
        "
        @click="$router.push('/locations')"
      >
        <v-btn text>
          <v-icon color="white">mdi-arrow-left-thin</v-icon>
          <span class="body-2 white--text pr-1">
            {{ $t("locations.label") }}
          </span>
        </v-btn>
      </div>

      <v-img
        :aspect-ratio="16 / 9"
        width="100vw"
        :src="image"
        @error="onImageError"
        class="mb-1"
      />
      <div class="d-flex flex-column px-2">
        <!-- <v-progress-linear
          :active="true"
          :indeterminate="true"
          top
          color="primary accent-4"
        ></v-progress-linear> -->
        <div class="d-flex align-center ma-2">
          <span class="primary--text text--darken-2 text-h6 mr-auto">{{
            currentArea.name
          }}</span>

          <!-- Play/Stop button -->
          <VoiceDescriptionButton :description="currentArea.description"></VoiceDescriptionButton>
        </div>
        <v-divider></v-divider>

        <div
          class="d-flex flex-column align-start justify-space-between px-2 py-4"
        >
          <span class="body-2 mb-16" v-html="currentArea.description"> </span>
        </div>
      </div>
    </div>

    <!-- COMMENTS -->
    <!-- COMMENTS -->
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import VoiceDescriptionButton from "../../components/VoiceDescriptionButton.vue";

export default {
  components:{
    VoiceDescriptionButton
  },
  data() {
    return {
      failed_image: false,
    };
  },

  computed: {
    ...mapState({
      currentArea: (state) => state.areas.currentArea,
    }),

    image() {
      // return this.failed_image
      //   ? "https://iguide.mobics.gr/images//ilioupoli_opt_.jpg"
      //   : `https://iguide.mobics.gr/images//${
      //       this.currentArea.image.file_name.split(".")[0]
      //     }_opt_.jpg`;
      return this.currentArea.image
    },
  },

  methods: {
    ...mapActions(["toggleSound"]),

    onImageError() {
      this.failed_image = true;
    },
  },
};
</script>

<style scoped lang="scss">
</style>