<template>
  <div class="d-flex flex-column component-wrapper secondary results" :key="$route.fullPath">
    <!-- Return button -->
    <div
      class="white rounded-pill d-flex align-self-start return-button-sticky elevation-5"
      @click="$router.push(`/health`)"
    >
      <v-btn text>
        <v-icon color="secondary">mdi-arrow-left-thin</v-icon>
        <span
          class="body-2 secondary--text pr-1"
        >
          {{$t('general.categories_label')}}
        </span>
      </v-btn>
    </div>

    <div
      class="
        d-flex
        flex-column
        px-2
      "
    >
      <!-- Header -->
      <span class="d-flex align-self-start white--text font-weight-bold text-h5">
        {{ $t(`health.title`) }}
      </span>
      <div class="d-flex flex-row align-center mb-2 ">
        <span class="caption grey--text px-2">
        <v-icon style="font-size:1.3rem" color="grey">{{ category.icon }}</v-icon> {{ category.name }}
        </span>
        <v-btn color="primary" class="ml-auto" v-if="category.information" @click="openInfo()">
          <v-icon >mdi-information</v-icon>
          {{$t("general.information")}}
        </v-btn>
      </div>

      <!-- Loader -->
      <div
        v-if="loader"
        class="d-flex flex-column align-center flex-grow-1"
        style="height: 500px"
      >
        <v-progress-circular indeterminate color="white" size="60">
        </v-progress-circular>
      </div>

      <!-- Results -->
      <div
        v-else-if="!loader && content.length>0"
        v-for="(entry, i) in content"
        :key="i"
        class="d-flex flex-column px-2"
        style="width: 100%"
      >
        <div
          @click="openEntry(entry)"
          class="
            d-flex
            justify-start
            align-center
            white--text
            route
            font-weight-thin
            body-1
            mb-2
          "
        >
          <span class="mr-auto">{{ entry.name }}</span>
          <v-btn icon color="white">
            <v-icon> mdi-arrow-right</v-icon>
          </v-btn>
        </div>

        <v-divider
          v-if="i != content.length - 1"
          class="mb-2"
          style="width: 100% !important"
          color="white"
        ></v-divider>
      </div>

      <span v-else-if="!loader && content.length==0" class="white--text px-2">{{$t(`health.not_found`)}}</span>
    </div>

    <v-dialog v-model="info" v-if="category.information">
      <v-card>
        <div style="position: sticky; top:0; z-index: 1; width: 100%;">
          <v-toolbar flat>
            <v-toolbar-title>
              {{$t("general.information")}}
            </v-toolbar-title>

            <v-btn icon @click="closeInfo()"> <v-icon>mdi-close</v-icon> </v-btn>
          </v-toolbar>

          <v-divider></v-divider>
        </div>

        <v-card-text class="pa-3" style="overflow: scroll;" v-html="category.information">
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  async created() {
    try {
      var entries = [];
      if(this.$store.state.base.mobileLang){
        entries = require(`../../../examples/health_${this.$store.state.base.mobileLang.code}.json`);

        if(this.$route.query.category){
          var categories = require(`../../../examples/health_categories_${this.$store.state.base.mobileLang.code}.json`);
          this.category = categories.filter(category => {
            return category.id === parseInt(this.$route.query.category);
          })[0]

          entries = entries.filter(entry => {
            return entry.category_id === this.category.id;
          });
        }
      }
      this.content = entries;
      this.loader=false;
    } catch (e) {
      this.loader = false;
      console.log(e);
    }
  },

  data() {
    return {
      loader: true,
      content: [],
      category: [],
      info: false
    }
  },
  methods: {
    openEntry(entry){
      this.$router.push(`${entry.id}`);
    },
    openInfo(){
      this.info = true;
    },
    closeInfo(){
      this.info = false;
    }
  }
};
</script>

<style scoped lang="scss">
.results {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 0;
  overflow-y: auto;
}

.user-routes {
  top: 50vh; //suggested-routes height
  width: 100vw;
  height: calc(100% - 50vh);
  position: absolute;
  overflow-y: auto !important;
}

.route {
  width: 100%;
}
</style>