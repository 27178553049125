<template>
  <div class="pa-1 d-flex flex-column" style="width: 100%;">
  <v-expansion-panels v-model="panelIndex">
    <!-- Locations -->
    <v-card v-for="(item,i) in items" :key="i" :color="item.color" class="ma-1" width="100%">
      <v-expansion-panel>
        <v-img
          :src="item.img"
          class="white--text align-end"
          gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
          height="200px"
          aspect-ratio="16 / 9"
          @click="togglePanel(i)"
        >
        </v-img>

        <v-expansion-panel-header :color="item.color" class="px-4">
          <template v-slot:actions>
            <div v-if="panelIndex===i">
              <v-icon color="white">
                mdi-minus
              </v-icon>
              <span class="white--text">{{$t("general.less")}}</span>
            </div>
            <div v-else>
              <v-icon color="white">
                mdi-plus
              </v-icon>
              <span class="white--text">{{$t("general.more")}}</span>
            </div>
          </template>
          <v-card-title class="home-text white--text text-wrap font-weight-bold">{{item.label}}</v-card-title>
        </v-expansion-panel-header>

        <v-expansion-panel-content :color="item.color">
          <v-card-text class="white--text text-wrap">
            {{item.description}}
          </v-card-text>

          <div class="d-flex flex-column"> 
            <v-btn
              class="justify-start text-wrap dynamic"
              :to="item.path"
              color="white"
              text
              style="display: inline-block;
              height: auto;
              margin-bottom: 2px;
              margin-top:2px;
              padding: 8px;"
            >
              <v-icon size="25" class="accent rounded-circle mr-2 pa-1">{{
                item.icon
              }}</v-icon>

            {{ item.link }} 
            
            <v-icon class="ml-auto">mdi-open-in-new</v-icon>
            </v-btn>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-card>
  </v-expansion-panels>
  </div>
</template>

<script>
  export default {
    async created(){
      if(this.$store.state.base.mobileLang){
       this.items=require(`../../../examples/home_${this.$store.state.base.mobileLang.code}.json`)
      }
    },
    data() {
      return {
        panelIndex:undefined,
        items: [],
      }
    },
    methods: {
      togglePanel(panel_num){
        if(this.panelIndex == panel_num){
          this.panelIndex = undefined;
        }
        else{
          this.panelIndex = panel_num;
        }
      }
    }
  };
</script>

<style scoped lang="scss">
.dynamic{
  display: inline-block;
  height: auto;
  margin-bottom: auto;
  margin-top:auto;
  padding: auto;
}

.home-text {
  font-size: max(25px,2vw) !important;
  line-height: 2.5rem;
  padding: 4px;
}

.text-wrap {
  word-break: break-word;
}
</style>