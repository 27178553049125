<template>
  <div class="component-wrapper secondary d-flex flex-column">
    <h1 class="white--text font-weight-bold text-h5 px-2">
      {{ $t("gastronomy.title") }}
    </h1>
    <span class="caption grey--text mb-3 px-2">
      {{ $t("gastronomy.select") }}
    </span>
    <!-- <v-icon size="50"> mdi-checkbox-multiple-outline</v-icon>
        <span> Όλες </span> -->
    <v-container>
      <v-row>
        <v-col
          v-for="(category, i) in categories"
          :key="i"
          cols="6"
          class="card d-flex flex-column"
          @click="onCategorySelect(category)"
        >
          <div class="card-2 d-flex flex-column align-center justify-center">
            <v-icon color="white" size="45"> {{ category.icon }}</v-icon>
            <span class="white--text body-1 text-center">
              {{ category.name }}
            </span>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
    
<script>
export default {
  created(){
    if(this.$store.state.base.mobileLang){
      this.categories=require(`../../../examples/gastronomy_categories_${this.$store.state.base.mobileLang.code}.json`);
    }
  },
  data() {
    return {
      categories: [
      ],
    };
  },

  methods: {
    onCategorySelect(category) {
      this.$router.push(`/gastronomy/${category.id}`);
    },
  },
};
</script>
    
    <style scoped lang="scss">
.card {
  height: 150px !important;
  width: 100px !important;
}

.card-2 {
  height: 100% !important;
  width: 100% !important;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 8px;
}

.card-2:hover {
  transition: 0.3s ease;
  background: rgba(255, 255, 255, 0.3);
}
</style>