<template>
  <div class="component-wrapper d-flex flex-column align-center">
    <v-btn
      @click="$router.push('/login')"
      icon
      color="secondary"
      class="align-self-start"
    >
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>

    <v-form @submit.prevent="onSubmit" class="d-flex flex-column mt-7">
      <h1 class="secondary--text font-weight-bold text-h5">
        {{ $t("reset.title") }}
      </h1>
      <span style="width: 272px" class="caption grey--text text--darken-3 mb-4">
        {{ $t("reset.subtitle") }}
      </span>

      <v-text-field
        v-model="email"
        solo
        type="email"
        color="primary"
        style="width: 272px"
        :error-messages="emailErrors"
      >
        <template v-slot:label>
          <div class="body-2">
            {{ $t("reset.email_label") }}
          </div>
        </template>
      </v-text-field>

      <v-btn color="secondary body-2" type="submit">
        {{ $t("reset.button") }}
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      email: null,
    };
  },

  validations: {
    email: {
      required,
      email,
    },
  },

  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      if (!this.$v.email.required) errors.push("Required field");
      if (!this.$v.email.email)
        errors.push("Please enter a valid email address");
      return errors;
    },
  },

  methods: {
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.$store.commit("toggleSnackbar", {
        open: true,
        text: this.$t("reset.success_snackbar"),
        color: "success",
      });

      this.$router.push("/login");
    },
  },
};
</script>

<style scoped lang="scss">
.v-btn:not(.v-btn--round).v-size--default {
  min-width: 0 !important;
}
</style>