<template>
  <div class="component-wrapper absolute-content">
    <div
      v-if="loader"
      class="d-flex flex-column align-center justify-center flex-grow-1"
      style="height: 500px"
    >
      <v-progress-circular indeterminate color="primary" size="60">
      </v-progress-circular>
    </div>


    <div v-else class="d-flex flex-column px-2">
      <div
        class="
          secondary
          rounded-pill
          d-flex
          align-self-start
          return-button-sticky
          elevation-5
        "
        @click="$router.back()"
      >
        <v-btn text>
          <v-icon color="white">mdi-arrow-left-thin</v-icon>
          <span class="body-2 white--text pr-1">
            {{$t("routes.title")}}
          </span>
        </v-btn>
      </div>

      <h1 class="mt-4 text-decoration-underline">Route page is under construction.</h1>

      <div class="d-flex align-center ma-2">
        <span class="primary--text text--darken-2 text-h6 mr-auto">{{
          currentRoute.name
        }}</span>
        <!-- Play/Stop button -->
        <VoiceDescriptionButton :description="currentRoute.description"></VoiceDescriptionButton>
      </div>
      <v-divider></v-divider>

      <div
        class="d-flex flex-column align-start justify-space-between px-2 py-4"
      >
        <span class="body-2 mb-16" v-html="currentRoute.description"> </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState,mapActions } from 'vuex';
import VoiceDescriptionButton from "../../components/VoiceDescriptionButton.vue";

export default{
  components:{
    VoiceDescriptionButton
  },
  async created(){
    await this.getRoute(parseInt(this.$route.params.route_id));
    this.loader = false;
  },
  data(){
    return{
      loader: true,
    }
  },
  methods:{
    ...mapActions({getRoute:'routes/getRoute'})
  },
  computed: {
    ...mapState({
      currentRoute: (state) => state.routes.currentRoute,
    }),
  }
}
</script>

<style>
.return-button {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
</style>