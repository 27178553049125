<template>
  <div class="component-wrapper d-flex flex-column secondary pa-3">
    <div
      v-for="(item, i) in menu"
      :key="i + item.text"
      class="d-flex flex-column align-start mb-3"
      @click="$router.push(`${item.path}`)"
    >
      <span text class="text-h6 white--text text flex-grow-0 mb-4">
        {{ item.text }}
        <v-icon class="white--text" v-if="item.icon">{{item.icon}}</v-icon>
      </span>
      <v-divider style="width: 100% !important"></v-divider>
    </div>

    <div class="mt-auto mb-8 align-self-center d-flex flex-column">
      <span class="body-1 white--text text-center"> Version 1.0.0 </span>
      <div class="caption white--text text-center">@2023 Silver Guide</div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: {
        open: false,
        tempLang: null,
      },
    };
  },

  computed: {
    menu() {
      return [
        {
          text: this.$t("info.about"),
          path: "/info/about",
        },

        {
          text: this.$t("info.terms"),
          path: "/info/terms_and_policies",
        },

        {
          text: this.$t("info.contact"),
          path: "/info/contact",
        },
      ];
    },
  },
};
</script>

<style scoped lang="scss">
</style>