<template>
  <div style="width: 100%">
    <!-- Loader -->
    <div
      v-if="loader"
      class="d-flex flex-column align-center justify-center flex-grow-1"
      style="height: 500px"
    >
      <v-progress-circular indeterminate color="primary" size="60">
      </v-progress-circular>
    </div>

    <div v-else>
      <!-- Map -->
      <l-map
        class="map"
        ref="map"
        :zoom="
          pois.length == 1 ?
          zoom
          :
          parseInt(area.zoom)
        "
        :center="
          pois.length == 1 ?
          [parseFloat(pois[0].latitude), parseFloat(pois[0].longitude)]
          :
          [parseFloat(area.latitude), parseFloat(area.longitude)]
        "
        :options="{ 
          zoomControl: false ,
          minZoom: 2,
          maxBounds: bounds,
          maxBoundsViscosity: 1.0
        }"
      >
        <l-tile-layer
          :url="url"
          :attribution="attribution"
          :detectRetina="true"
        ></l-tile-layer>

        <!-- Info button -->
        <l-control position="topright" v-if="$route.path.split('/')[4] != 'map'">
          <div
            class="secondary rounded-pill px-2 d-flex align-center"
            @click="openDialog(pois[0])"
            style="padding: 0 !important;"
          >
            <v-btn text>
              <v-icon color="white">mdi-text-box-multiple</v-icon>

              <span
              class="body-2 white--text pr-1"
              >
                {{$t("general.information")}}
              </span>
            </v-btn>
          </div>
        </l-control>

        <!-- Back button -->
        <l-control position="topleft">
          <div
            class="secondary rounded-pill d-flex align-center"
            @click="
              $route.path.split('/')[4] == 'map'
                ? $router.push(`/locations/area/${currentArea.id}`)
                : $router.back()
            "
          >
            <v-btn text>
              <v-icon color="white">mdi-arrow-left-thin</v-icon>

              <span
                class="body-2 white--text pr-1"
              >
                {{
                  $route.path.split("/")[4] == "map"
                    ? currentArea.name
                    : $t("pois.title")
                }}
              </span>
            </v-btn>
          </div>
        </l-control>

        <l-marker
          @click="openDialog(poi)"
          v-for="(poi, i) in pois"
          :key="i"
          :lat-lng="[parseFloat(poi.latitude), parseFloat(poi.longitude)]"
          :draggable="false"
          :zIndexOffset="-1"
        ></l-marker>
      </l-map>

      <!-- Info -->
      <PoiDialog class="dialog" :ppoi="dialog.poi" v-if="dialog.open" @close="closeDialog" />
    </div>
  </div>
</template>

<script>
import PoiDialog from "../../../components/PoiDialog.vue";
import axios from "axios";
import { LMap, LTileLayer, LMarker, LControl } from "vue2-leaflet";

import Leaflet,{ Icon } from "leaflet";

import { mapState,mapActions } from "vuex";

// Icon fix
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
export default {
  components: {
    PoiDialog,
    LMap,
    LTileLayer,
    LMarker,
    LControl,
  },

  async created() {
    // try {
    //   if (this.$route.path.split("/")[4] == "pois") {
    //     const res = await axios.get(
    //       `${process.env.VUE_APP_BASE_URL}/pois/${this.$route.params.poi_id}`
    //     );
    //     this.pois.push(res.data.poi);
    //   } else if (this.$route.path.split("/")[1] == "pois") {
    //     const res = await axios.get(
    //       `${process.env.VUE_APP_BASE_URL}/pois/${this.$route.params.poi_id}`
    //     );
    //     this.pois.push(res.data.poi);
    //   } else { //Area map
    //     // const res = await axios.get(
    //     //   `${process.env.VUE_APP_BASE_URL}/areas/${this.$route.params.area_id}/pois`
    //     // );
    //     // this.pois = res.data.pois;
    //     this.area = this.$store.state.areas.areas.filter(area => {
    //       return area.id === this.$route.params.area_id;
    //     })[0]
    //   }

    //   this.loader = false;
    // } catch (e) {
    //   console.log(e);
    // }

    if (this.$route.path.split("/")[4] == "pois") {
      await this.getPoi(parseInt(this.$route.params.poi_id));
    } else if (this.$route.path.split("/")[1] == "pois") {
      
    } else { //Area map
      await this.getAreaPois();
    }
    this.area=this.currentArea;
    this.loader = false;
  },

  data() {
    return {
      area: null,
      map: null,

      loader: true,

      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 13,

      dialog: {
        open: false,
        poi: null,
      },
      bounds: Leaflet.latLngBounds(Leaflet.latLng(-90, -200), Leaflet.latLng(90, 200)),
    };
  },

  computed: {
    ...mapState({
      currentArea: (state) => state.areas.currentArea,
      currentPoi: (state) => state.pois.currentPoi,
      pois: (state) => state.pois.pois,
    }),
  },

  methods: {
    ...mapActions({getAreaPois:'getAreaPois',getPoi:'pois/getPoi'}),
    openDialog(poi) {
      this.dialog = {
        open: true,
        poi,
      };
    },

    closeDialog() {
      this.dialog = {
        open: false,
        poi: null,
      };
    },
  },
};
</script>

<style scoped lang='scss'>
.map{
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 0
}

.dialog{
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 0;
  overflow: auto;
}
</style>