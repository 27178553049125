import { Geolocation } from '@capacitor/geolocation';
import { Preferences } from '@capacitor/preferences';

export default {
  state: {
    ui: {
      fontSize: "16px",//Default 16px
      iconSize: "25px",
    },
    setUp: false,
    filters: {
      category: null,
      area: null,
    },
    languages: [
      {
        name: null,
        engName: 'Default System Language',
        code: 'en',
      },

      {
        name: 'Ελληνικά',
        engName: 'Greek',
        code: 'el',
      },

      {
        name: 'English',
        engName: 'English',
        code: 'en',
      },

      // {
      //   name: 'Français',
      //   engName: 'French',
      //   code: 'fr',
      // },

      // {
      //   name: 'Deutsch',
      //   engName: 'German',
      //   code: 'de',
      // },

      // {
      //   name: 'Italiano',
      //   engName: 'Italian',
      //   code: 'it',
      // },

      // {
      //   name: 'Pусский',
      //   engName: 'Russian',
      //   code: 'ru',
      // },

      // {
      //   name: '繁體中文',
      //   engName: 'Chinese',
      //   code: 'zh', //maybe need prefix zh
      // },
    ],

    location: {
      latitude: 0,
      longitude: 0,
    },

    mobileLang: JSON.parse(localStorage.getItem('mobileLang')) || null,
    sound: '1',
    snackbar: {
      open: false,
      text: null,
      color: null,
    },
  },

 

  mutations: {
    toggleSound(state, sound) {
      state.sound = sound?.toString();
    },

    getLocation(state, location) {
      state.location = location
    },

    setFilters(state, filters) {
      state.filters = filters
    },

    setMobileLang(state, lang) {
      if(typeof lang == 'string'){
        const langObj =  state.languages.find(el=> el.code == lang)
        state.mobileLang = langObj
        localStorage.setItem('mobileLang',JSON.stringify(langObj))

      }
      else {

        state.mobileLang = lang
        localStorage.setItem('mobileLang',JSON.stringify(lang))
      }
     
    },

    toggleSnackbar(state, snackbar) {
      state.snackbar = {
        ...state.snackbar,
        ...snackbar,
      };
    },

    changeUISize(state, {fontSize, iconSize}){
      state.ui.fontSize = fontSize;
      state.ui.iconSize = iconSize;
      document.documentElement.style['font-size']=fontSize;
    },

    setup(state){
      state.setUp = true;
    }
  },

  actions: {
    async getLocation({ commit }) {
      try {

        const coordinates = await Geolocation.getCurrentPosition();
        commit('getLocation', {
          latitude: coordinates.coords.latitude,
          longitude: coordinates.coords.longitude
        })



      }
      catch (e) {
        console.log(e)
      }
    },

    async toggleSound({ commit }, sound) {
      try {
        await Preferences.set({
          key: 'sound',
          value: sound,
        });
        commit('toggleSound', sound)
      }
      catch (e) {
        throw e;
      }
    },


  }
};
